import React from "react";
import { useTranslation } from "react-i18next";

const SignOutBtn = ({ action }) => {
  const { t } = useTranslation();
  return (
    <button onClick={action} className="ui red google button">
      <i className="google icon" />
      {t("auth.signOut")}
    </button>
  );
};

export default SignOutBtn;
