import React from "react";
import { Card, Button, Icon } from "semantic-ui-react";
import Tags from "../../../../common/Tags";
import Delete from "../Delete";

const ListItem = ({ data, editAction }) => {
  const { name, shortName, tags, _id } = data;

  return (
    <Card key={_id} fluid>
      <Card.Content>
        <Card.Header>
          {name}
          <Button.Group icon floated="right">
            <Delete item={data} />
            <Button onClick={editAction} id={_id}>
              <Icon name="cog" />
            </Button>
          </Button.Group>
        </Card.Header>
        <Card.Meta>{shortName}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Tags tags={tags} />
      </Card.Content>
    </Card>
  );
};

export default ListItem;
