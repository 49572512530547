import { LOAD_LIST } from "../actions/peopleAction";

const INTIAL_STATE = {
  people: [],
};

const peopleReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST:
      return { ...state, people: action.payload };
    default:
      return state;
  }
};

export default peopleReducer;
