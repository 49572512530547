import React, { useContext } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { FormField, SaveButton } from "../config/imports";
import { Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
// import BookingContext from "./BookingContext";
import Context from "./Context";

const ConfirmForm = () => {
  const { t } = useTranslation();
  const { bookerState, onSubmit } = useContext(Context);
  const initialValues = bookerState;

  const validate = (a) => {
    const emails = a.email.match(
      /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );

    const errors = {};

    if (emails === null) {
      errors.email = t("validation.wrong.email");
    }

    if (!a.subject) {
      errors.subject = t("validation.missing.field");
    }

    if (a.subject.length < 10) {
      errors.subject = t("validation.length.tooShort");
    }
    if (a.subject.length > 500) {
      errors.subject = t("validation.length.tooLong");
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      // validationSchema={form.validationSchema}
      validate={validate}
    >
      {(formikApi) => (
        <Segment.Group>
          <Form className="ui fluid form">
            <Segment color="red">
              {/* <FormField
                name="email"
                label={t("booking.book.wiz.confirm.email")}
              /> */}
              <div className="field">
                <label>
                  <h5>
                    {t("booking.book.wiz.confirm.email")}
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="ui left pointing red label"
                    />
                  </h5>
                </label>
                <Field
                  name="email"
                  placeholder="Email"
                  className="ui field"
                  onChange={formikApi.handleChange}
                />
              </div>
              <FormField
                name="subject"
                component="textarea"
                label={t("booking.book.wiz.confirm.subject")}
              />
            </Segment>
          </Form>
          <Segment>
            <SaveButton
              touched={formikApi.touched}
              errors={formikApi.errors}
              submitForm={formikApi.submitForm}
            />
          </Segment>
        </Segment.Group>
      )}
    </Formik>
  );
};

export default ConfirmForm;
