import React, { useContext, useState } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import UserContext from "./UserContext";

const ActionBtns = ({ item, change }) => {
  const { changeRole } = useContext(UserContext);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const renderRemoveBtn = () => {
    return (
      <></>
      // <Button
      //   icon="user cancel"
      //   disabled={isLoading || change !== item._id}
      //   compact
      //   onClick={async () => {
      //     await removeUser(item._id);
      //   }}
      //   title={t("user.mgmt.remove")}
      //   color="red"
      // ></Button>
    );
  };

  return (
    <div>
      <Button
        icon="exchange"
        loading={isLoading}
        disabled={isLoading || change !== item._id}
        secondary
        compact
        onClick={async () => {
          setLoading(true);
          await changeRole(item._id, item.role);
          setLoading(false);
        }}
        title={t("user.mgmt.changeRole")}
      ></Button>
      {renderRemoveBtn()}
    </div>
  );
};

export default ActionBtns;
