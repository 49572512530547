import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useLanguageSelector = () => {
  const { i18n } = useTranslation();

  const [state, setState] = useState({ check: true, language: "" });

  const language = window.navigator.language;

  useEffect(() => {
    let flag = "";
    if (state.check) {
      switch (language) {
        case "pt-BR":
          i18n.changeLanguage("pt_br");
          flag = <i className="br flag" />;
          break;

        default:
          i18n.changeLanguage("en_us");
          flag = <i className="us flag" />;
          break;
      }
      setState({ check: false, language, flag });
    }
  }, [state, i18n, language]);
};

export default useLanguageSelector;
