import React, { useEffect, useState } from "react";
import { AppContext, PageHeader, options, Header } from "../../config/imports";
import { save, load } from "../../actions/skillAction";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMyToast, ToastContainer, toast } from "../../config/toastDecorator";
import CrudSwitch from "../../../common/crud/CrudSwitch";
import ItemTemplate from "./templates/ListItem";
import Form from "./Form";
import * as Yup from "yup";
import { load as loadPeople } from "../../actions/peopleAction";
import { load as loadLevel } from "../../actions/levelAction";
import { load as loadType } from "../../actions/typeAction";

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myToast = useMyToast();
  const [items, setItems] = useState(null);
  const [state, setState] = useState({
    action: options.list,
    id: null,
  });
  const skills = useSelector((state) => state.appModule.skill.skill.skills);

  useEffect(() => {
    const fetch = async () => {
      if (!skills[0]) {
        const items = await dispatch(load());
        setItems(items);
      } else {
        setItems(skills);
      }
      dispatch(loadPeople());
      dispatch(loadType());
      dispatch(loadLevel());
    };
    fetch();
  }, [skills, items, dispatch]);

  const reloadList = async () => {
    setItems(await dispatch(load()));
  };

  const setAction = (action, id) => {
    setState({ ...state, action, id });
  };

  const onSubmit = async (payload) => {
    await setState({ action: options.list });
    await dispatch(save(payload)).then((isSuccess) => {
      isSuccess
        ? toast.success(myToast.success, { ...myToast.params })
        : toast.error(myToast.error);
    });
    setItems(await dispatch(load()));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
    owner: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
    skillType: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
  });

  const providerData = {
    action: {
      current: state.action,
      targetId: state.id,
      options: options,
      set: setAction,
      onSubmit: onSubmit,
      reloadList,
    },
    items,
    title: t("skill.skill.title"),
    templates: {
      card: ItemTemplate,
    },
    form: {
      fields: Form,
      validationSchema: validationSchema,
      initialValues: {
        name: "",
        owner: "",
        skillType: "",
        skillLevels: [],
      },
    },
  };

  return (
    <AppContext.Provider value={providerData}>
      <Header />
      <PageHeader />
      <CrudSwitch />
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
