import React, { useEffect, useState } from "react";
import Actions from "./ManagementBtns";
import UserContext from "./UserContext";
import { Button, Grid, Icon, Table, Checkbox } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { load, switchRole } from "../actions/userAction";
import { useNavigate } from "react-router-dom";
import {
  PageHeader,
  toast,
  ToastContainer,
  useMyToast,
  Header,
} from "../config/imports";

const invitesPath = "/users/invites";

const App = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { auth } = useSelector((state) => state.session);
  const myToast = useMyToast();
  const [change, setChange] = useState(null);
  const [state, setState] = useState({
    users: [],
    willFetch: true,
    goToInvites: false,
  });
  const permission = {
    admin: t("user.role.admin"),
    supervisor: t("user.role.supervisor"),
    user: t("user.role.user"),
  };
  const switchTo = {
    user: "supervisor",
    supervisor: "admin",
    admin: "user",
  };

  // eslint-disable-next-line
  const fetch = async () => {
    if (!state.willFetch) {
      return;
    }
    const users = await load();
    setState({ ...state, users: users.sort(sortName), willFetch: false });
  };

  useEffect(() => {
    fetch();
  }, [state, fetch]);

  const sortName = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const renderTableRows = () => {
    if (!state.users || !state.users.length) {
      return noResultsRow();
    }
    return state.users.map((item) => row(item));
  };

  const changeRole = async (id, role) => {
    try {
      const user = await switchRole(id, switchTo[role]);
      if (!user) throw Error("cant change user's role");
      updateStateUsers(user);
      toast.success(myToast.success, { ...myToast.params });
    } catch (error) {
      toast.error(myToast.error);
    }
  };

  const updateStateUsers = (user) => {
    const userList = state.users.filter((item) => user._id !== item._id);
    userList.push(user);
    setState({ ...state, users: userList.sort(sortName) });
  };

  const row = (item) => {
    return (
      <Table.Row key={item._id}>
        <Table.Cell collapsing>
          <Checkbox
            slider
            disabled={item.email === auth.email}
            onChange={(a, b) => {
              if (b.checked) {
                setChange(item._id);
              } else {
                setChange(null);
              }
            }}
            checked={item._id === change}
          />
        </Table.Cell>
        <Table.Cell textAlign="left">{item.name}</Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell textAlign="center">{permission[item.role]}</Table.Cell>
        <Table.Cell textAlign="center">
          <Actions item={item} change={change} callback={() => {}} />
        </Table.Cell>
      </Table.Row>
    );
  };

  const noResultsRow = () => {
    return (
      <Table.Row>
        <Table.Cell colSpan={5} textAlign="center">
          {t("user.mgmt.noResults")}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <Header />
      <UserContext.Provider value={{ changeRole }}>
        <Grid textAlign="center" style={{ height: "40vh" }} verticalAlign="top">
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={t("user.mgmt.title")} icon="mail" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table celled compact definition>
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign="left">
                      {t("user.mgmt.name")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{t("user.mgmt.email")}</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      {t("user.mgmt.role")}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      {t("user.mgmt.actions")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>{renderTableRows()}</Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan="4">
                      <Button
                        secondary
                        icon
                        size="small"
                        floated="right"
                        labelPosition="left"
                        onClick={() => {
                          history(invitesPath);
                        }}
                      >
                        <Icon name="user plus" /> {t("user.invite.inviteUser")}
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ToastContainer />
      </UserContext.Provider>
    </>
  );
};

export default App;
