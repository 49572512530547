import React from "react";
import { Header } from "../components/page";
import CompanyApp from "../modules/company/components/App";
import useCompany from "../modules/company/hooks/company";

const Company = () => {
  const { hasCompany } = useCompany();

  const renderHeaderIfHasCompany = (hasCompany) => {
    if (hasCompany === true) {
      return <Header />;
    }
  };

  return (
    <>
      {renderHeaderIfHasCompany(hasCompany)}
      <CompanyApp />
    </>
  );
};

export default Company;
