import React, { useContext } from "react";
import { Formik } from "formik";
import { Segment } from "semantic-ui-react";
import AppContext from "../../../components/AppContext";
import SaveButton from "./SaveButton";

//CrudForm is usually called by CrudSwitch

const CrudForm = (props) => {
  const { action, form } = useContext(AppContext);

  if (!props.initialValues) {
    console.log("Missing Initial Values");
    return;
  }

  const Fields = form.fields;

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={action.onSubmit}
      validationSchema={form.validationSchema}
    >
      {(formikApi) => (
        <Segment.Group>
          <Segment color="red">
            <Fields
              api={formikApi}
              action={action}
              defaultValues={props.initialValues}
            />
          </Segment>
          <Segment>
            <SaveButton
              touched={formikApi.touched}
              errors={formikApi.errors}
              submitForm={formikApi.submitForm}
            />
          </Segment>
        </Segment.Group>
      )}
    </Formik>
  );
};

export default CrudForm;
