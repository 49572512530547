import React from "react";
import { Header } from "../components/page";
import { Navigate } from "react-router-dom";
import HomeApp from "../modules/home/App";
import useCompany from "../modules/company/hooks/company";

const Home = () => {
  const { hasCompany } = useCompany();

  const redirectNoCompanyUser = (hasCompany) => {
    if (hasCompany === false) {
      return <Navigate to="/company" />;
    }
  };

  return (
    <>
      {redirectNoCompanyUser(hasCompany)}
      <Header />
      <HomeApp />
    </>
  );
};

export default Home;
