import React, { useContext } from "react";
import stages from ".";
import { Grid, GridRow, GridColumn } from "semantic-ui-react";
import Context from "../Context";
import LoadingSchedule from "../LoadingSchedule";
import { useTranslation } from "react-i18next";
import StatusBoard from "../StatusBoard";

const End = () => {
  const { t } = useTranslation();
  const { bookerState, setBookerState, defaultState } = useContext(Context);
  const { schedule } = bookerState;
  const { display } = bookerState;

  if (display !== stages.end) return <></>;

  const reset = () => {
    setBookerState({
      ...defaultState,
      display: stages.type,
    });
  };

  const handleLoading = () => {
    if (schedule.loading) {
      return (
        <GridColumn>
          <LoadingSchedule text={t("booking.book.wiz.end.loading")} />
        </GridColumn>
      );
    }

    return renderStatus();
  };

  const renderStatus = () => {
    return (
      <GridColumn>
        <StatusBoard end={{ success: schedule.success }} resetFn={reset} />
      </GridColumn>
    );
  };

  return (
    <Grid>
      <GridRow>{handleLoading()}</GridRow>
    </Grid>
  );
};

export default End;
