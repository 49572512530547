import React from "react";
import { Card, Button, Icon } from "semantic-ui-react";
import Delete from "./DeleteBtn";

const ListItem = ({ data, editAction }) => {
  const { fullName, _id } = data;

  return (
    <Card key={_id} fluid>
      <Card.Content>
        <Card.Header>
          {fullName}
          <Button.Group icon floated="right">
            <Delete item={data} />
            <Button onClick={editAction} id={_id}>
              <Icon name="cog" />
            </Button>
          </Button.Group>
        </Card.Header>
        <Card.Meta>{}</Card.Meta>
      </Card.Content>
      <Card.Content extra></Card.Content>
    </Card>
  );
};

export default ListItem;
