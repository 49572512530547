import React from "react";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageHeader from "../modules/common/pages/Header";

const UserApp = ({ props }) => {
  const invitePath = "/users/invites";
  const mgmtPath = "/users/management";
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <Grid textAlign="center" style={{ height: "40vh" }} verticalAlign="top">
        <Grid.Row>
          <Grid.Column>
            <PageHeader title={t("user.mgmt.title")} icon="mail" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid textAlign="center" style={{ height: "0vh" }} verticalAlign="middle">
        <Grid.Column>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical>{t("company.switch.or")}</Divider>

              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Header icon>
                    <Icon name="user" />
                  </Header>
                  <Button
                    onClick={() => {
                      navigate(mgmtPath);
                    }}
                    secondary
                  >
                    {t("user.btn.manage")}
                  </Button>
                </Grid.Column>

                <Grid.Column>
                  <Header icon>
                    <Icon name="plus" />
                  </Header>
                  <Button
                    onClick={() => {
                      navigate(invitePath);
                    }}
                    secondary
                  >
                    {t("user.btn.invite")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default UserApp;
