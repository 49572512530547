import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";

const LoadingSchedule = ({ text }) => {
  return (
    <Segment placeholder>
      <Segment.Inline>
        <Dimmer active inverted>
          <Loader size="large">{text}</Loader>
        </Dimmer>
      </Segment.Inline>
    </Segment>
  );
};

export default LoadingSchedule;
