import React, { useContext } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import AppContext from "../../../components/AppContext";
import CrudMenu from "./CrudMenu";

const CrudHeader = ({ title, hideOptions, menu }) => {
  const context = useContext(AppContext);

  const handleTitle = () => {
    if (title) {
      return title;
    } else {
      return context ? context.title : null;
    }
  };

  const renderOptions = () => {
    if (menu) {
      const Menu = menu;
      return <Menu />;
    }

    if (hideOptions) {
      return <></>;
    }

    return <CrudMenu />;
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{/* Breadcrumbs */}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="12">
          <Header as="h2">
            <Icon name="settings" />
            <Header.Content>{handleTitle()}</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width="4" textAlign="right">
          {renderOptions()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CrudHeader;
