import { api, getHeaders } from "../config/api";

export const LOAD_LIST = "@USER/LOAD_LIST";

const route = {
  company: "/users/company",
  role: "/users/role",
};

/**
 * Load all company users
 *
 * - Used by User Management
 *
 * @returns
 */
export const load = async () => {
  try {
    const res = await api.get(route.company, await getHeaders());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const switchRole = async (id, role) => {
  try {
    const res = await api.patch(route.role, { id, role }, await getHeaders());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// export const save = (payload) => async (dispatch) => {
//   const data = payload;

//   // if (data._id === undefined) {
//   //   try {
//   //     const res = await api.post(route, data, await getHeaders());
//   //     return res;
//   //   } catch (e) {
//   //     console.log(e);
//   //     return null;
//   //   }
//   // } else {
//   // try {
//   //   const res = await api.patch(
//   //     `${route}/${data._id}`,
//   //     data,
//   //     await getHeaders()
//   //   );
//   //   return res;
//   // } catch (e) {
//   //   console.log(e);
//   //   return null;
//   // }
//   // }
// };
