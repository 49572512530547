import React, { useContext } from "react";
import { options } from "./options";
import AppContext from "../../../components/AppContext";
import ListItems from "./ListItems";
import CrudForm from "./CrudForm";

//Crud switch is usually called by App

const CrudSwitch = () => {
  const { action, form, items } = useContext(AppContext);

  const getValuesToUpdate = () => {
    const valueToUpdate = items.filter((item) => item._id === action.targetId);

    return valueToUpdate[0];
  };

  const routeComponents = () => {
    switch (action.current) {
      case options.create:
        return <CrudForm initialValues={form.initialValues} />;

      case options.list:
        return <ListItems />;

      case options.edit:
        return <CrudForm initialValues={getValuesToUpdate()} />;

      default:
        return <ListItems />;
    }
  };

  return routeComponents();
};

export default CrudSwitch;
