import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Table } from "semantic-ui-react";
import {
  PageHeader,
  toast,
  ToastContainer,
  useMyToast,
} from "../config/imports";
import { useTranslation } from "react-i18next";
import UserContext from "../components/UserContext";
import { load } from "../actions/inviteAction";
import Actions from "./InviteBtns";
import InviteModal from "./InviteModal";
import { invite, remove } from "../actions/inviteAction";
import { Header } from "../config/imports";

const App = () => {
  const { t } = useTranslation();
  const myToast = useMyToast();
  const [state, setState] = useState({
    invites: null,
    willFetch: true,
    modal: false,
  });
  const [isOpen, setModal] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!state.willFetch) {
        return;
      }
      const invites = await load();
      setState({ ...state, invites, willFetch: false });
    };
    fetch();
  }, [state]);

  const renderState = (isSent, isJoined) => {
    const [sent, joined, pending] = [
      t("user.invite.sent"),
      t("user.invite.joined"),
      t("user.invite.pending"),
    ];
    if (isJoined) {
      return joined;
    }

    if (isSent) {
      return sent;
    }

    return pending;
  };

  const row = (item) => {
    return (
      <Table.Row key={item._id}>
        <Table.Cell collapsing>
          {/* <Checkbox slider checked={true} /> */}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {new Date(item.createdAt).toLocaleDateString(navigator.language, {})}
        </Table.Cell>
        <Table.Cell>{item.email}</Table.Cell>
        <Table.Cell textAlign="center">
          {renderState(item.isSent, item.isJoined)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Actions item={item} callback={() => {}} />
        </Table.Cell>
      </Table.Row>
    );
  };

  const noResultsRow = () => {
    return (
      <Table.Row>
        <Table.Cell colSpan={5} textAlign="center">
          {t("user.invite.noResults")}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTableRows = () => {
    if (!state.invites || !state.invites.length) return noResultsRow();

    return state.invites.map((item) => {
      return row(item);
    });
  };

  const toggleModal = () => {
    const modal = isOpen ? false : true;
    setModal(modal);
  };

  const onSubmit = async ({ email }) => {
    toggleModal();
    await inviteUser(email);
  };

  const inviteUser = async (email) => {
    const res = await invite({ email });
    if (res) {
      setState({ ...state, willFetch: true });
      toast.success(myToast.success, { ...myToast.params });
    } else {
      toast.error(myToast.error);
    }
  };

  const removeInvite = async (_id) => {
    try {
      await remove(_id);
      setState({ ...state, willFetch: true });
      toast.success(myToast.success, { ...myToast.params });
    } catch (error) {
      toast.error(myToast.error);
    }
  };

  return (
    <>
      <Header />
      <UserContext.Provider value={{ onSubmit, inviteUser, removeInvite }}>
        <Grid textAlign="center" style={{ height: "40vh" }} verticalAlign="top">
          <Grid.Row>
            <Grid.Column>
              <PageHeader title={t("user.invite.title")} icon="mail" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table celled compact definition>
                <Table.Header fullWidth>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign="center">
                      {t("user.invite.created")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("user.invite.emailAddress")}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      {t("user.invite.status")}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      {t("user.invite.actions")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>{renderTableRows()}</Table.Body>

                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan="4">
                      <Button
                        secondary
                        icon
                        size="small"
                        floated="right"
                        labelPosition="left"
                        onClick={toggleModal}
                      >
                        <Icon name="user plus" /> {t("user.invite.inviteUser")}
                      </Button>
                      <InviteModal open={isOpen} callback={toggleModal} />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ToastContainer />
      </UserContext.Provider>
    </>
  );
};

export default App;
