import React, { useContext, useState, useEffect } from "react";
import BookingContext from "./BookingContext";
import { Divider, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useMyToast, toast } from "../../common/toastDecorator";
import { save } from "../actions/scheduleAction";
import { useSelector } from "react-redux";
import Types from "./stages/Types";
import Levels from "./stages/Levels";
import Slots from "./stages/Slots";
import Confirm from "./stages/Confirm";
import End from "./stages/End";
import stages from "./stages";
import Context from "./Context";
import MyHeader from "./stages/MyHeader";

const Booker = () => {
  const { t } = useTranslation();
  const appContext = useContext(BookingContext);
  const stateModule = useSelector((state) => state.appModule.skill);
  const { module } = appContext;
  const myToast = useMyToast();
  const defaultState = {
    display: stages.type,
    email: [],
    subject: "",
    type: {
      id: null,
    },
    level: {
      id: null,
    },
    slots: {
      people: [],
    },
    schedule: {
      loading: true,
      payload: {},
      success: null,
    },
    statusBoard: {
      loading: false,
    },
  };
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    getSkilledPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const makeTitle = (levelId, typeId) => {
    const type = stateModule.type.types.filter((e) => e._id === typeId)[0];
    const level = stateModule.level.levels.filter((e) => e._id === levelId)[0];

    return `${level.name} ${type.name}`;
  };

  const getTimeZone = async () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const onSubmit = async (data) => {
    const { email, slots, subject, level, type } = data;
    const slot = slots.selected;
    const title = await makeTitle(level.id, type.id);
    const timeZone = await getTimeZone();

    const emails = email.match(
      /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );

    // console.log(data);
    // console.log(slot);
    // console.log(emails);

    const payload = {
      peopleId: slot.peopleId,
      start: slot.start,
      end: slot.end,
      email: emails,
      subject: subject,
      title: title,
      timeZone,
      typeId: type.id,
      levelId: level.id,
    };

    try {
      setState({ ...state, display: stages.end });
      const submitted = await save(payload);
      setState({
        ...state,
        display: stages.end,
        schedule: {
          payload,
          loading: false,
          success: submitted ? true : false,
        },
      });
      toast.success(myToast.success, { ...myToast.params });
    } catch (error) {
      setState({
        ...state,
        display: stages.end,
        schedule: {
          payload,
          loading: false,
          success: false,
        },
      });
      toast.error(myToast.error);
    }
  };

  const onSelectType = (e, { value }) => {
    setState({ ...defaultState, display: stages.level, type: { id: value } });
  };

  const onSelectLevel = (e, { value }) => {
    setState({
      ...state,
      display: stages.slot,
      level: { id: value },
      slots: {
        ...state.slots,
        loading: true,
        willUpdate: true,
      },
      statusBoard: { ...state.statusBoard, loading: true },
    });
  };

  const onSelectSlot = (e, { slot }) => {
    setState({
      ...state,
      display: stages.confirm,
      slots: { ...state.slots, selected: slot },
    });
  };

  const getSkilledPeople = () => {
    if (!state.type.id || !state.level.id) {
      return;
    }
    const skills = module.skills.filter((skill) => {
      return skill.skillType === state.type.id;
    });
    // dentre os skills, busco os people que excecutam o level escolhido
    const people = [];

    skills.forEach((skill) => {
      skill.skillLevels.forEach((level) => {
        if (state.level.id === level) {
          people.push(skill.owner);
        }
      });
    });

    if (people === undefined) {
      console.log("Can't find services for this type");
      return;
    }

    //faço a busca pelos schedules
    if (!people.length) {
      console.log("No Staff with the skill required");
      return;
    }

    if (state.slots.willUpdate) {
      setState({
        ...state,
        slots: {
          people: people,
          willUpdate: false,
          willFetch: true,
        },
      });
    }
  };

  const context = {
    setBookerState: setState,
    bookerState: state,
    defaultState,
    onSubmit,
  };

  return (
    <Context.Provider value={context}>
      <Breadcrumb>
        <Breadcrumb.Section link>
          {t("booking.book.wiz.type.title")}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>
          {t("booking.book.wiz.level.title")}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>
          {t("booking.book.wiz.slots.title")}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link>
          {t("booking.book.wiz.confirm.title")}
        </Breadcrumb.Section>
      </Breadcrumb>
      <Divider />
      <MyHeader />
      <Types action={onSelectType} />
      <Levels action={onSelectLevel} />
      <Slots action={onSelectSlot} />
      <Confirm />
      <End />
    </Context.Provider>
  );
};

export default Booker;
