import React, { useContext } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import CompanyContext from "./CompanyContext";

const Switch = ({ display }) => {
  const { t } = useTranslation();
  const { displaySwitch, actionOnNew } = useContext(CompanyContext);

  if (displaySwitch !== true) {
    return <></>;
  }

  return (
    <Grid textAlign="center" style={{ height: "70vh" }} verticalAlign="middle">
      <Grid.Column>
        <Segment placeholder>
          <Grid columns={2} stackable textAlign="center">
            {/* <Divider vertical>{t("company.switch.or")}</Divider> */}

            <Grid.Row verticalAlign="middle">
              <Grid.Column>
                <Header icon>
                  <Icon name="file outline" />
                </Header>
                <Button onClick={actionOnNew} secondary>
                  {t("company.switch.create")}
                </Button>
              </Grid.Column>

              {/* <Grid.Column>
                <Header icon>
                  <Icon name="sign-in" />
                </Header>
                <Button secondary>{t("company.switch.join")}</Button>
              </Grid.Column> */}
            </Grid.Row>
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Switch;
