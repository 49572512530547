import React from "react";
import { Header } from "../components/page";
import BookingApp from "../modules/booking/components/App";

const Planner = () => {
  return (
    <>
      <Header />
      <BookingApp />
    </>
  );
};

export default Planner;
