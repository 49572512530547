import React from "react";
import { Card, Button, Icon } from "semantic-ui-react";
import { useSelector } from "react-redux";
import Tags from "../../../../common/Tags";
import Delete from "../Delete";

const ListItem = (props) => {
  const skill = useSelector((state) => state.appModule.skill);
  const { editAction, data } = props;
  const { name, _id, skillLevels, skillType, owner } = data;

  //TODO: Trocar para item de lista lista paginada

  const renderType = () => {
    if (!skill.type.types.length) {
      return null;
    }

    const type = skill.type.types.filter((item) => {
      return item._id === skillType;
    });

    if (type.length) {
      return type[0].name;
    }
  };

  const renderOwner = () => {
    if (!skill.people.people.length) {
      return null;
    }

    const people = skill.people.people.filter((item) => {
      return item._id === owner;
    });

    if (people.length > 0) {
      return people[0].fullName;
    }
  };

  const renderLevels = () => {
    if (!skill.level.levels.length) {
      return [];
    }

    const skillLevel = skillLevels.map((levelId) => {
      return skill.level.levels.filter((item) => {
        return item._id === levelId;
      });
    });

    if (skillLevel.length) {
      return skillLevel[0].name;
    }
  };

  return (
    <Card key={_id} fluid>
      <Card.Content>
        <Card.Header>
          {name}
          <Button.Group icon floated="right">
            <Delete item={data} />
            <Button onClick={editAction} id={_id}>
              <Icon name="cog" />
            </Button>
          </Button.Group>
        </Card.Header>
        <Card.Meta>
          <strong>{renderOwner()}</strong>
        </Card.Meta>
        <Card.Meta>{renderType()}</Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Tags tags={renderLevels()} />
      </Card.Content>
    </Card>
  );
};

export default ListItem;
