import { getTokenInFromLocalStorage } from "../AuthService";

const getHeaders = async () => {
  const token = await getTokenInFromLocalStorage();

  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};

export { getHeaders };
