import React, { useEffect, useState } from "react";
import { PageHeader, options } from "../config/imports";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMyToast, ToastContainer, toast } from "../../common/toastDecorator";
import * as Yup from "yup";
import Switch from "./Switch";
import Home from "./Home";
import Form from "./Form";
import CompanyContext from "./CompanyContext";

import { save, load } from "../actions/";

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myToast = useMyToast();
  const [company, setCompany] = useState({ name: null });
  const [state, setState] = useState({
    action: options.list,
    editing: false,
    create: false,
    displaySwitch: false,
    displayHome: true,
  });

  useEffect(() => {
    const fetch = async () => {
      const company = await dispatch(load());

      if (!company) {
        setState({ ...state, displaySwitch: true });
        return;
      }

      setCompany(company);
      dispatch(load());
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = async (payload) => {
    const { name } = payload;

    await dispatch(save({ ...company, name })).then((isSuccess) => {
      isSuccess
        ? toast.success(myToast.success, { ...myToast.params })
        : toast.error(myToast.error);
    });
    setCompany(await dispatch(load()));
    actionOnSave();
  };

  const actionOnSave = () => {
    setState({ ...state, create: false, editing: false, displayHome: true });
  };

  const actionOnNew = () => {
    setState({ ...state, create: true, editing: true, displaySwitch: false });
  };

  const actionOnEdit = () => {
    setState({ ...state, editing: true, displayHome: false });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(60, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
  });

  const title = company.name ? company.name : t("company.header");
  const companyName = company.name
    ? company.name
    : t("company.form.companyName");

  const context = {
    company,
    title: title,
    validationSchema: validationSchema,
    initialValues: {
      name: companyName,
    },
    editing: state.editing,
    create: state.create,
    displaySwitch: state.displaySwitch,
    displayHome: state.displayHome,
    onSubmit: onSubmit,
    actionOnNew: actionOnNew,
    actionOnEdit: actionOnEdit,
  };

  return (
    <CompanyContext.Provider value={context}>
      <PageHeader hideOptions={true} title={context.title} />
      <Switch />
      <Form />
      <Home />
      <ToastContainer />
    </CompanyContext.Provider>
  );
};

export default App;
