import React, { useState, useEffect } from "react";
import { Logo } from "../components/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, Loader } from "semantic-ui-react";
import { enroll, loadInvite } from "../modules/company/actions";

const Enroll = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const url = window.location.href.split("/enroll/");
  const token = url[1];

  const { t } = useTranslation();

  const [state, setState] = useState({
    invite: null,
    token: null,
    company: null,
    goHome: false,
  });

  useEffect(() => {
    if (state.token) {
      return;
    }
    const fetch = async () => {
      const invite = await loadInvite(token);
      setState({ ...state, invite, token });
    };
    fetch();
  }, [state, token]);

  if (state.goHome) {
    navigate("/home");
  }

  const enrollToken = async () => {
    const { enrolled } = await enroll(token);

    if (enrolled) {
      setState({ ...state, goHome: true });
    }
  };

  const goHome = async () => {
    setState({ ...state, goHome: true });
  };

  const renderEnrollBtn = () => {
    return (
      <>
        <div className="ui basic segment">
          {t("company.enroll.ask")}{" "}
          {state.invite ? state.invite.company.name : null}?
        </div>
        <Button onClick={goHome}>{t("btn.no")}</Button>
        <Button primary onClick={enrollToken}>
          {t("btn.yes")}
        </Button>
      </>
    );
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 400 }}>
        <Logo />
        <div className="ui basic segment">{t("domain.slogan")}</div>
        {state.invite ? renderEnrollBtn() : <Loader active inline="centered" />}
      </Grid.Column>
    </Grid>
  );
};

export default Enroll;
