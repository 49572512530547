import React from "react";
import { Label } from "semantic-ui-react";

const Tags = (props) => {
  const { tags } = props;

  if (!tags || !tags.length) {
    return <></>;
  }

  const renderTags = () => {
    const labeledTags = tags.map((tag) => {
      if (!tag) {
        return null;
      }

      return <Label key={tag}>{tag}</Label>;
    });

    return labeledTags;
  };

  return <Label.Group>{renderTags()}</Label.Group>;
};

export default Tags;
