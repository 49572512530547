import { SIGN_IN, SIGN_OUT, CHECK_IN } from "./types";
import api from "../../../middleware/AuthAPI";
import { getHeaders } from "../config/api";

export const registerUser =
  ({ name, email, googleId, company, role }) =>
  async (dispatch) => {
    const data = { name, email, googleId, company, role };

    await dispatch({
      type: SIGN_IN,
      payload: data,
    });
    return;
  };

export const checkIn = () => async (dispatch) => {
  const res = await api.get("/users/check", await getHeaders());

  await dispatch({
    type: CHECK_IN,
    payload: res.data,
  });
  return;
};

export const tokenUserLogout = () => async (dispatch) => {
  const data = {};
  const response = await api.post("/users/logout", data, await getHeaders());

  await dispatch({
    type: SIGN_OUT,
  });

  return response.status;
};

export const signUp = async (payload) => {
  try {
    const response = await api.post("/users", payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const tryToLogin = async (payload) => {
  try {
    const response = await api.post("/users/login", payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
