import React from "react";
import { Grid, Header, Icon } from "semantic-ui-react";

//TODO: Padronizar com os outros headers da applicação

const AppHeader = ({ title }) => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{/* Breadcrumbs */}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="12">
          <Header as="h2">
            <Icon name="settings" />
            <Header.Content>{title}</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width="4" textAlign="right"></Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AppHeader;
