const stageNumbers = [0, 1, 2, 3, 4];
const [type, level, slot, confirm, end] = stageNumbers;
const stages = {
  type,
  level,
  slot,
  confirm,
  end,
  length: stageNumbers.length,
};

export default stages;
