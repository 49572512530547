import { useEffect, useState } from "react";
import { tryToLogin, registerUser, tokenUserLogout, checkIn } from "./actions";
import { useDispatch } from "react-redux";

const DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar email";

//TODO: verify how to merge handleGoogleApi with HandleGoogleAuth2

export const handleGoogleApi = (callback) => {
  window.gapi.load("client:auth2", async () => {
    await window.gapi.client.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: DOCS,
      scope: SCOPES,
    });

    const googleUser = window.gapi.auth2.getAuthInstance();
    callback(googleUser);
  });
};

export const handleGoogleAuth2 = (callback) => {
  window.gapi.load("auth2", async () => {
    const auth2 = await window.gapi.auth2.init({
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: DOCS,
      scope: SCOPES,
    });

    auth2.grantOfflineAccess().then(callback);
  });
};

export const saveTokenInLocalStorage = (token) => {
  window.localStorage.setItem("booky_token", token);
};

export const getTokenInFromLocalStorage = () => {
  return window.localStorage.getItem("booky_token");
};

export const clearDataFromLocalStorage = () => {
  window.localStorage.clear();
};

export const handleSignIn = (callback1, callback2) => {
  handleGoogleApi(async (googleUser) => {
    const idToken = await googleUser.currentUser.get().getAuthResponse()
      .id_token;
    const login = await tryToLogin({ idToken });

    if (!login) {
      return;
    }

    callback1(login, callback2);
  });
};

export const handleRefresh = (callback1, callback2) => {
  handleGoogleApi(async (googleUser) => {
    const user = await getUserInfo(googleUser);

    callback1(user, callback2);
  });
};

export function useLoginStatus() {
  const [isLoogedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    handleStatus();
  });

  const handleStatus = async () => {
    //se tem token e está logado no google, loga no redux.
    handleGoogleApi(async (auth) => {
      if (auth.isSignedIn.get()) {
        setIsLoggedIn(true);
      }
    });
  };

  return isLoogedIn;
}

export const getUserInfo = async (auth) => {
  return {
    googleId: await auth.currentUser.get().getId(),
    name: await auth.currentUser.get().getBasicProfile().getName(),
    email: await auth.currentUser.get().getBasicProfile().getEmail(),
  };
};

/**
 * React Authentication methods
 *
 * Used in SignIn/SignOut actions
 * also offers check state function, reseting navigation and session if needed
 * 
 * The auth functions can be used with a callback: ex: so after operation
 * is it possible run operations
   const signOutAction = () => {
    signOut(() => {
        setState({ signOut: true });
      });
    };
 *
 * @returns {void}
 */
export function useAuthentication() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    isSignedIn: getTokenInFromLocalStorage() ? true : false,
  });
  const { isSignedIn } = state;

  useEffect(() => {
    const token = getTokenInFromLocalStorage();

    if (token) {
      setState({ isSignedIn: true });
    }

    handleGoogleApi(async (googleUser) => {
      if (googleUser.isSignedIn.get()) {
        return;
      }

      clearDataFromLocalStorage();
      setState({ isSignedIn: false });
    });
  }, [state.isSignedIn]);

  /**
   * Sign in User
   * Used in:
   *  - Login Page
   * @param {function} callback
   */
  const signIn = async (callback) => {
    handleGoogleApi(async (googleUser) => {
      await googleUser.signIn();

      if (googleUser.isSignedIn.get()) {
        handleSignIn(_registerIn, callback);
      }
    });
  };

  /**
   * Register / Update logged user in the redux state and localStorage
   *
   * @param {object} login { user, token }
   * @param {*} callback function to be executed after registration
   */
  const _registerIn = async (login, callback) => {
    const { user, token } = login;

    dispatch(registerUser(user));

    saveTokenInLocalStorage(token);

    callback();

    //console.log("Signed inn");
  };

  const _refreshUpdate = async (user, callback) => {
    dispatch(checkIn());

    callback();
  };

  /**
   * Sign user out
   * Used in:
   *  - Login Page
   *  - UserOptions component
   * @param {function} callback
   */
  const signOut = async (callback) => {
    handleGoogleApi(async (googleUser) => {
      await googleUser.signOut();

      dispatch(tokenUserLogout());

      clearDataFromLocalStorage();

      callback();

      //console.log("Signed out");
    });
  };

  /**
   * Refresh User
   *
   * This function will check if user is still logged with google
   * executed in PrivateRoute Componente
   *
   * @param {function} badUsercallback to be executed if user is not ok
   */
  const refresh = async (badUsercallback, userOkCallback = () => {}) => {
    if (!getTokenInFromLocalStorage()) {
      badUsercallback();
      return false;
    }

    handleGoogleApi(async (googleUser) => {
      if (googleUser.isSignedIn.get()) {
        handleRefresh(_refreshUpdate, userOkCallback);
      } else {
        clearDataFromLocalStorage();
        badUsercallback();
        //window.location.reload();
      }
    });

    return true;
  };

  return { isSignedIn, signIn, signOut, refresh };
}
