import React from "react";
import { Grid, Loader } from "semantic-ui-react";

const CustomLoader = () => {
  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "50vh" }}
        verticalAlign="middle"
      >
        <Grid.Column>
          <Loader active inline="centered" />
        </Grid.Column>
      </Grid>
    </>
  );
};

export default CustomLoader;
