import { api, getHeaders } from "../config/api";

export const LOAD_LIST = "@SKILL/TYPE/LOAD_LIST";

const route = "/skill/type";

export const deleteType = async (id) => {
  const res = await api.delete(`${route}/${id}`, await getHeaders());
  return res;
};

export const save = (payload) => async () => {
  const putTagsAsArray = (obj) => {
    if (!Array.isArray(obj.tags)) {
      obj.tags = obj.tags.split(",");
    }

    return obj;
  };

  const data = await putTagsAsArray(payload);

  if (data._id === undefined) {
    try {
      const res = await api.post(route, data, await getHeaders());
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  } else {
    try {
      const res = await api.patch(
        `${route}/${data._id}`,
        data,
        await getHeaders()
      );
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
};

export const load = () => async (dispatch) => {
  const res = await api.get(route, await getHeaders());
  await dispatch({
    type: LOAD_LIST,
    payload: res.data,
  });

  return res.data;
};
