import React from "react";
import { Grid, Icon, Header } from "semantic-ui-react";

const ComponentName = ({ title, icon, Options }) => {
  const renderOptions = () => {
    if (Options) {
      return <Options />;
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>{/* Breadcrumbs */}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="12">
          <Header as="h2">
            <Icon name={icon ? icon : "settings"} />
            <Header.Content>{title}</Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column width="4" textAlign="right">
          {renderOptions()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ComponentName;
