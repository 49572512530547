import React, { useContext } from "react";
import Context from "../Context";
import { Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import stages from ".";
import BookingContext from "../BookingContext";

const NavButtons = ({ stage, onNav }) => {
  const context = useContext(Context);
  const bookContext = useContext(BookingContext);
  const { color } = bookContext;
  const { bookerState, setBookerState } = context;
  const { t } = useTranslation();

  if (stages.end === bookerState.display) {
    return <></>;
  }

  // const isEnable = stage ? true : false;
  // const disabled = isEnable === false;
  // const btnColor = isEnable ? color.on : color.off;

  const renderPrevBtn = () => {
    if (bookerState.display <= 0) return <></>;
    return (
      <Button icon labelPosition="left" color={color.on} onClick={goToPrevious}>
        <Icon name="left arrow" />
        {t("btn.prev")}
      </Button>
    );
  };

  // const renderNextBtn = () => {
  //   console.log(stages.length);
  //   if (bookerState.display >= stages.length - 1) return <></>;
  //   return (
  //     <Button disabled={disabled} color={btnColor} onClick={goToNext}>
  //       {t("btn.next")}
  //     </Button>
  //   );
  // };

  // const goToNext = () => {
  //   const current = bookerState.display;
  //   const next = stages.length > current ? current + 1 : current;
  //   setBookerState({ ...bookerState, display: next });
  // };

  const goToPrevious = () => {
    const current = bookerState.display;
    const prev = current > 0 ? current - 1 : current;
    setBookerState({ ...bookerState, display: prev });
  };

  return (
    <>
      {renderPrevBtn()}
      {/* {renderNextBtn()} */}
    </>
  );
};

export default NavButtons;
