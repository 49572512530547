import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { load as loadSkills } from "../actions/skillAction";
import { load as loadPeople } from "../actions/peopleAction";
import { load as loadTypes } from "../actions/typeAction";
import { load as loadLevels } from "../actions/levelAction";

const useLoadBookingRequirements = (rows = []) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetch = async () => {
      if (!rows.length) {
        dispatch(loadSkills());
        dispatch(loadTypes());
        dispatch(loadLevels());
        dispatch(loadPeople());
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export { useLoadBookingRequirements };
