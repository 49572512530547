import { api, getHeaders } from "../config/api";
export const LOAD_LIST = "@CALENDAR/LOAD_LIST";
export const LOAD_EXTERNAL = "@CALENDAR/LOAD_EXTERNAL";

const route = "/calendar";

export const deleteCalendar = async (id) => {
  const res = await api.delete(`${route}/${id}`, await getHeaders());
  return res;
};

export const load = () => async (dispatch) => {
  const res = await api.get(route, await getHeaders());
  await dispatch({
    type: LOAD_LIST,
    payload: res.data,
  });

  return res.data;
};

export const loadExtCalendars = async (userId) => {
  const res = await api.get(
    `${route}/user/${userId}/ext/form`,
    await getHeaders()
  );

  return res.data;
};

export const save = (payload) => async (dispatch) => {
  const {
    externalId,
    externalName,
    externalSource,
    peopleId,
    userId,
    name,
    _id,
  } = payload;

  const data = {
    _id,
    name,
    peopleId,
    userId,
    external: { id: externalId, name: externalName, source: externalSource },
  };

  if (data._id === undefined) {
    try {
      const res = await api.post(route, data, await getHeaders());
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  } else {
    try {
      const res = await api.patch(
        `${route}/${data._id}`,
        data,
        await getHeaders()
      );
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
};
