import React, { useContext, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { deleteSkill } from "../../actions/skillAction";
import { AppContext } from "../../config/imports";
import { useTranslation } from "react-i18next";
import { useMyToast, toast } from "../../config/toastDecorator";

const Delete = ({ item }) => {
  const { t } = useTranslation();
  const myToast = useMyToast();

  const {
    action: { reloadList },
  } = useContext(AppContext);
  const [state, setState] = useState({
    modal: false,
    enabled: false,
  });

  const enableDelete = async () => {
    setState({ ...state, enabled: state.enabled === false });
    if (state.enabled) {
      switchModal();
    }
  };

  const switchModal = () => {
    const open = state.modal ? false : true;
    setState({
      ...state,
      modal: open,
      enabled: open,
    });
  };

  const deleteItem = async () => {
    setState({ ...state, loading: true });
    try {
      await deleteSkill(item._id);
      reloadList();
      toast.success(item.name + " " + t("skill.skill.deleted"), {
        ...myToast.params,
      });
    } catch (error) {
      toast.error(t("skill.skill.notDeleted"));
    }
  };

  return (
    <>
      <Button onClick={enableDelete} color={state.enabled ? "red" : null}>
        <Icon name="delete" />
      </Button>
      <Modal open={state.modal} onClose={switchModal}>
        <Modal.Header>{t("skill.skill.shallDelete")}?</Modal.Header>
        <Modal.Content>
          {t("skill.skill.alsoDeletes")} <strong> {item.name}. </strong>
          {t("skill.skill.shallContinue")}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => switchModal()}>
            {t("btn.cancel")}
          </Button>
          <Button positive onClick={() => deleteItem()}>
            {t("skill.skill.delete")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Delete;
