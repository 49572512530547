import React, { useContext, useEffect, useState } from "react";
import { Segment, Card, Divider, Grid, Input, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../components/AppContext";
import CrudMenu from "./CrudMenu";
import { Loader } from "../../../components/common";

const ListItems = () => {
  const { t } = useTranslation();

  const { action, items, templates } = useContext(AppContext);

  const Item = templates.card;

  const [state, setState] = useState({ items: [], filter: "" });

  useEffect(() => {
    setState({ ...state, items: items });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  if (items === null) {
    return <Loader />;
  }

  if (!items || !items.length) {
    return (
      <Segment placeholder>
        <div align="center">{t("form.list.empty")}</div>
        <Divider />
        <CrudMenu />
      </Segment>
    );
  }

  const editCard = (e, card) => {
    action.set(action.options.edit, card.id);
  };

  const renderCard = (data, editFnc) => {
    return <Item key={data._id} data={data} editAction={editFnc} />;
  };

  const renderItemsGroup = () => {
    const cards = items
      .filter((item) => {
        const name = () => {
          if (item.hasOwnProperty("name")) {
            return item.name.toLowerCase();
          }
          if (item.hasOwnProperty("fullName")) {
            return item.fullName.toLowerCase();
          }
          return "";
        };

        return name().includes(state.filter.toLowerCase());
      })
      .map((type) => {
        return renderCard(type, editCard);
      });

    if (!cards.length) {
      return (
        <div align="center">
          <Icon name="exclamation circle" />
          {t("data.noResults")}
        </div>
      );
    }

    return <Card.Group centered>{cards}</Card.Group>;
  };

  const filterResults = (obj, { value }) => {
    setState({ ...state, filter: value });
  };

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column></Grid.Column>
        <Grid.Column align={"right"}>
          <Input placeholder={t("data.search")} onChange={filterResults} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>{renderItemsGroup()}</Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ListItems;
