import React from "react";
import { t } from "i18next";
import { Card, Button, Icon } from "semantic-ui-react";
import Delete from "../Delete";

const ListItem = (props) => {
  const { editAction, data } = props;
  const { name, _id } = data;

  return (
    <Card key={_id} fluid>
      <Card.Content>
        <Card.Header>
          {name}
          <Button.Group icon floated="right">
            <Delete item={data} />
            <Button onClick={editAction} id={_id}>
              <Icon name="cog" />
            </Button>
          </Button.Group>
        </Card.Header>
        <Card.Meta>
          <strong>
            {t("calendar.calendar")}: {data.external.id}
          </strong>
        </Card.Meta>
        <Card.Meta>
          {t("calendar.provider")}: {data.external.source}
        </Card.Meta>
      </Card.Content>
    </Card>
  );
};

export default ListItem;
