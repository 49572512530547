import { api, getHeaders } from "../config/api";

export const LOAD_LIST = "@BOOKING/LOAD_LIST";
export const LOAD_EXTERNAL = "@BOOKING/LOAD_EXTERNAL";

const route = "/schedule";

export const fetchSchedules = async (people, type, level, period = 0) => {
  const body = { people, type, level, period };

  const res = await api.post(`${route}/free`, body, await getHeaders());

  return res.data;
};

export const save = async (payload) => {
  const data = { ...payload };

  if (data._id === undefined) {
    const res = await api.post(route, data, await getHeaders());
    return res;
  } else {
    const res = await api.patch(
      `${route}/${data._id}`,
      data,
      await getHeaders()
    );
    return res;
  }
};
