import React, { useContext, useState, useEffect } from "react";
import {
  Card,
  Header,
  Grid,
  GridRow,
  GridColumn,
  Icon,
  Dimmer,
  Loader,
  Segment,
} from "semantic-ui-react";
import stages from ".";
import Context from "../Context";
import { useTranslation } from "react-i18next";
import { fetchSchedules } from "../../actions/scheduleAction";

const Slots = ({ action }) => {
  const itemsPerRow = 4;
  const { t } = useTranslation();
  const { bookerState, setBookerState } = useContext(Context);
  const { level, type, slots, display } = bookerState;
  const { willUpdate, people } = slots;
  const defaultPeriod = { number: 0, loading: false };
  const [period, setPeriod] = useState(defaultPeriod);
  const defaultState = {
    freeSchedules: [],
    loading: true,
    noResults: true,
    schedules: [],
    selected: { id: null },
    touched: false,
    willFetch: true,
    willUpdate: willUpdate,
  };
  const [state, setState] = useState(defaultState);

  const getSlots = async (period = 0) => {
    const schedules = await fetchSchedules(people, type.id, level.id, period);
    const freeSchedules = await parseSlots(schedules, period);
    return { schedules, freeSchedules };
  };

  const parseSlots = (schedules, period) => {
    const hasSchedules = schedules.length > 0 ? true : false;
    const freeSchedules = [];
    let slotNumber = 0;

    if (hasSchedules) {
      for (const schedule of schedules) {
        let dayNumber = 0;
        const { peopleId } = schedule;

        for (const day of schedule.slots) {
          dayNumber++;

          const periodGroup = 1000000 * (period + 1);

          for (const slot of day) {
            slotNumber++;
            const id = dayNumber * 100000 + slotNumber + periodGroup;
            freeSchedules.push({ ...slot, peopleId, id, dayNumber });
          }
        }
      }
    }

    return freeSchedules;
  };

  useEffect(() => {
    const fetch = async () => {
      if (display !== stages.slot) return;
      if (!level.id || !type.id || !people.length) return;
      if (state.willFetch === false) return;

      setState({ ...state, schedules: [], freeSchedules: [] });

      const { schedules, freeSchedules } = await getSlots();

      setState({
        ...state,
        loading: false,
        willFetch: false,
        schedules,
        freeSchedules,
        noResults: freeSchedules.length ? false : true,
        touched: true,
      });

      setBookerState({
        ...bookerState,
        statusBoard: { ...bookerState.statusBoard, loading: false },
      });

      setPeriod(defaultPeriod);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [people]);

  if (display !== stages.slot && state.touched === true) {
    setState(defaultState);
    return <></>;
  }

  if (display !== stages.slot) return <></>;

  const addPeriod = async () => {
    const next = period.number + 1;
    await setPeriod({ ...period, loading: true });

    const { freeSchedules: schedules } = await getSlots(next);
    const freeSchedules = state.freeSchedules.concat(schedules);

    setState({
      ...state,
      freeSchedules,
      noResults: freeSchedules.length ? false : true,
    });
    setPeriod({ ...period, number: next, loading: false });
  };

  const renderAddBtn = () => {
    if (state.touched === false) {
      return;
    }

    if (period.loading) {
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      );
    }

    return (
      <Header as="h4" icon onClick={addPeriod}>
        <Icon name="plus" circular color={"grey"} />
      </Header>
    );
  };

  const renderNoSlots = () => {
    return (
      <Segment vertical>
        {t("booking.book.msg.noSlotsL1")} <br />{" "}
        {t("booking.book.msg.noSlotsL2")} <br />
      </Segment>
    );
  };

  const sortByStart = (slots) => {
    function compare(a, b) {
      if (a.start < b.start) {
        return -1;
      }
      if (a.start > b.start) {
        return 1;
      }
      return 0;
    }

    return slots.sort(compare);
  };

  const addPosition = (slots) => {
    const uniqueStarts = [];
    const slotList = [];

    for (const slot of slots) {
      const cSlot = { ...slot, position: 1 };
      if (uniqueStarts.includes(slot.start) === false) {
        slotList.push(cSlot);
      } else {
        slotList.push({ ...cSlot, position: cSlot.position + 1 });
      }
      uniqueStarts.push(slot.start);
    }

    return slotList;
  };

  function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  const reduceAndMix = (slots) => {
    const starts = [];
    const slotList = [];

    for (const slot of slots) {
      if (starts.includes(slot.start) === false) {
        starts.push(slot.start);
      }
    }

    for (const start of starts) {
      const group = slots.filter((slot) => slot.start === start);
      const index = getRandomArbitrary(0, group.length - 1);
      slotList.push(group[index]);
    }

    return slotList;
  };

  const renderSlotItems = () => {
    if (state.loading) {
      return;
    }

    if (state.noResults) return renderNoSlots();

    const slots = reduceAndMix(sortByStart(addPosition(state.freeSchedules)));

    return (
      <Card.Group itemsPerRow={itemsPerRow}>
        {slots.map((schedule) => {
          const { id, start, end } = schedule;
          const t1 = new Date(start);
          const t2 = new Date(end);
          return (
            <Card key={id} value={id} slot={schedule} onClick={action}>
              <Card.Content textAlign="center">
                <Card.Header>
                  <Header as="h4">
                    {t1.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}{" "}
                    -{" "}
                    {t2.toLocaleTimeString(navigator.language, {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Header>
                </Card.Header>
                <Card.Meta>
                  {t1.toLocaleDateString(navigator.language, {
                    weekday: "long",
                  })}
                  <br />
                  {t1.getDate()} de{" "}
                  {t1.toLocaleDateString(navigator.language, {
                    month: "long",
                  })}
                </Card.Meta>
              </Card.Content>
            </Card>
          );
        })}
      </Card.Group>
    );
  };

  return (
    <Grid>
      <GridRow>
        <GridColumn textAlign="center">{renderSlotItems()}</GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn textAlign="center">{renderAddBtn()}</GridColumn>
      </GridRow>
    </Grid>
  );
};

export default Slots;
