import { LOAD_EXTERNAL, LOAD_LIST } from "../actions/calendarAction";

const INTIAL_STATE = {
  calendars: [],
};

const reducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST:
      return { ...state, calendars: action.payload };
    case LOAD_EXTERNAL:
      return { ...state, externals: action.payload };
    default:
      return state;
  }
};

export default reducer;
