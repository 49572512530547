import React, { useContext, useEffect, useState } from "react";
import BookingContext from "../BookingContext";
import { Card, Header, Input, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import stages from ".";
import Context from "../Context";

const Types = ({ action }) => {
  const itemsPerRow = 4;
  const { t } = useTranslation();
  const appContext = useContext(BookingContext);
  const { module } = appContext;
  const context = useContext(Context);
  const { bookerState } = context;
  const { display } = bookerState;
  const [state, setState] = useState({ filter: "", items: [] });

  useEffect(() => {
    setState({ ...state, items: module.types });
    // eslint-disable-next-line
  }, []);

  if (display !== stages.type) return <></>;

  if (!module.types) return <></>;

  const filterItems = () => {
    const items = module.types.filter((item) => {
      const name = item.name.toLowerCase();
      const short = item.shortName.toLowerCase();
      const tags = item.tags.join().toLowerCase();

      if (
        name.includes(state.filter.toLowerCase()) ||
        short.includes(state.filter.toLowerCase()) ||
        tags.includes(state.filter.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    return items;
  };

  const filterResults = (obj, { value }) => {
    setState({ ...state, filter: value });
  };

  const items = filterItems();

  const renderNoResults = () => {
    if (items.length) {
      return;
    }

    return (
      <div align="center">
        <Icon name="exclamation circle" />
        {t("data.noResults")}
      </div>
    );
  };

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column></Grid.Column>
        <Grid.Column textAlign="right">
          <Input
            value={state.filter}
            placeholder={t("data.search")}
            onChange={filterResults}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Card.Group itemsPerRow={itemsPerRow}>
            {items.map(({ _id, name, shortName }) => {
              return (
                <Card key={_id} value={_id} onClick={action}>
                  <Card.Content>
                    <Card.Header>
                      <Header as="h4">{shortName}</Header>
                    </Card.Header>
                    <Card.Meta>{name}</Card.Meta>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
          {renderNoResults()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Types;
