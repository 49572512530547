import axios from "axios";
import { clearDataFromLocalStorage } from "../services/auth/AuthService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
});

/**
 * INTERCEPTOR
 *
 * Se ocorre falha na autenticação, limpa o local storage e recarrega a página
 *
 */
instance.interceptors.response.use(
  function (res) {
    //console.log("res axios");
    return res;
  },
  function (e) {
    switch (e.response.status) {
      case 401:
        clearDataFromLocalStorage();
        window.location.reload();
        break;

      default:
        throw e;
    }
    return e;
  }
);

export default instance;
