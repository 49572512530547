import React, { useContext } from "react";
import {
  Segment,
  Header,
  Icon,
  Dimmer,
  Loader,
  Button,
  Divider,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Context from "./Context";

const SelectionBoard = ({ end, resetFn }) => {
  const context = useContext(Context);
  const { level, type, slots, loading } = context.bookerState;

  const module = useSelector((state) => state.appModule.skill);
  const { t } = useTranslation();

  const renderType = () => {
    if (!type.id) {
      return t("booking.book.wiz.type.statusBoard");
    }

    const typeObj = module.type.types.filter((e) => e._id === type.id)[0];

    return <Header.Subheader>{typeObj.name}</Header.Subheader>;
  };

  const renderLevel = () => {
    if (!level.id) {
      return;
    }
    const levelObj = module.level.levels.filter((e) => e._id === level.id)[0];

    return levelObj.name;
  };

  const renderSlotTime = () => {
    if (!slots.selected) {
      return;
    }

    const slot = slots.selected;

    const start = new Date(slot.start);
    const end = new Date(slot.end);

    return (
      <Header>
        {start.toLocaleDateString(navigator.language, {
          day: "numeric",
          month: "long",
        })}
        {", "}
        {start.toLocaleDateString(navigator.language, {
          weekday: "long",
        })}
        {", "}
        {t("clock.from")}{" "}
        {start.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}
        {t("clock.h")} {t("clock.to")}{" "}
        {end.toLocaleTimeString(navigator.language, {
          hour: "2-digit",
          minute: "2-digit",
        })}
        {t("clock.h")}
      </Header>
    );
  };

  const renderIcon = () => {
    if (end === undefined) {
      return <Icon name="calendar" circular />;
    }

    switch (end.success) {
      case true:
        return <Icon name="check" circular color={"green"} />;

      case false:
        return <Icon name="cancel" circular color={"red"} />;

      default:
        return <Icon name="question circle" circular color={"blue"} />;
    }
  };

  const renderStatusMessage = () => {
    if (end === undefined) {
      return "";
    }

    switch (end.success) {
      case true:
        return t("booking.book.success");

      case false:
        return t("booking.book.fail");

      default:
        return "";
    }
  };

  const renderResetButton = () => {
    if (end === undefined) {
      return "";
    }

    switch (end.success) {
      case true:
        return (
          <Segment.Inline>
            <Divider />
            <Button onClick={resetFn} icon labelPosition="left" primary>
              <Icon name="left arrow" /> {t("btn.prev")}{" "}
            </Button>
          </Segment.Inline>
        );

      case false:
        return (
          <Segment.Inline>
            <Divider />
            <Button onClick={resetFn} primary>
              {t("booking.book.failBtn")}
            </Button>
          </Segment.Inline>
        );

      default:
        return "";
    }
  };

  const renderPeopleInCharge = () => {
    if (!context.bookerState.schedule.payload.peopleId) {
      return;
    }
    const people = module.people.people.filter(
      (people) => context.bookerState.schedule.payload.peopleId === people._id
    );

    return `${t("booking.book.inCharge")}: ${people[0].fullName}`;
  };

  const renderBoard = () => {
    if (loading) {
      return (
        <Segment placeholder>
          <Segment.Inline>
            <Dimmer active inverted>
              <Loader size="large">{t("booking.book.loading")}</Loader>
            </Dimmer>
          </Segment.Inline>
        </Segment>
      );
    } else {
      return (
        <Segment placeholder textAlign="center">
          <Header as="h2" icon>
            {renderIcon()}
            {renderStatusMessage()} {renderLevel()}
            {renderType()}
          </Header>
          <Segment.Inline>
            {renderPeopleInCharge()}
            {renderSlotTime()}
          </Segment.Inline>
          {renderResetButton()}
        </Segment>
      );
    }
  };

  return <>{renderBoard()}</>;
};

export default SelectionBoard;
