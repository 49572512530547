import React from "react";
import { Button } from "semantic-ui-react";
import { options } from "./options";

const ListBtn = (props) => {
  if (props.action === options.list) {
    return <></>;
  }

  return (
    <Button
      circular
      icon="list alternate outline"
      floated={"right"}
      onClick={props.onClick}
    />
  );
};

export default ListBtn;
