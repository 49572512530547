import { SIGN_IN, SIGN_OUT, CHECK_IN } from "../actions/types";

const INTIAL_STATE = {
  name: null,
  email: null,
  googleId: null,
  company: null,
  role: null,
};

const authReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
      };
    case CHECK_IN:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_OUT:
      return { ...state, ...INTIAL_STATE };
    default:
      return state;
  }
};

export default authReducer;
