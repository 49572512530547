import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import ConfirmationModal from "./ConfirmationModal";

const SaveButton = (props) => {
  const { t } = useTranslation();

  const [resource, setResource] = useState({ modal: { isOpened: false } });

  const { touched, errors, submitForm } = props;

  const rederModalActions = (submitBtn) => {
    return (
      <>
        <Button onClick={() => riseModal(false)} className="ui button negative">
          {t("btn.no")}
        </Button>
        <Button
          onClick={submitForm}
          positive
          labelPosition="right"
          icon="checkmark"
          content={t("btn.confirm")}
        />
      </>
    );
  };

  const riseModal = (isRised) => {
    setResource({
      modal: { isOpened: isRised },
    });
  };

  const renderSaveBtn = (isBtnEnabled) => {
    if (isBtnEnabled) {
      return (
        <div
          className="ui button primary"
          onClick={(e) => {
            riseModal(true);
          }}
        >
          {t("btn.save")}
        </div>
      );
    } else {
      return <div className="ui button">{t("btn.save")}</div>;
    }
  };

  const enableBtn = (touched, errors) => {
    if (Object.keys(touched).length && !Object.keys(errors).length) {
      return true;
    }
    if (Object.keys(errors).length) {
      return false;
    }

    return false;
  };

  return (
    <div>
      {renderSaveBtn(enableBtn(touched, errors))}
      <ConfirmationModal
        status={resource.modal.isOpened}
        actionButtons={rederModalActions}
        header={t("form.modal.confirmation.title")}
        content={t("form.modal.confirmation.question")}
      />
    </div>
  );
};

export default SaveButton;
