/***
 * CALENDAR MGMT FORM
 *
 * - CustomForm is usually called by CrudForm
 */
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "formik";
import { loadExtCalendars } from "../actions/calendarAction";
import { loadUsers } from "../config/imports";
import {
  FormField,
  FormDropdown,
  AppContext,
  useAvailablePeople,
} from "../config/imports";

const CustomForm = (props) => {
  const { action } = useContext(AppContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { api: formikApi, defaultValues } = props;
  const [extCalendars, setExtCalendars] = useState([]);
  const [users, setUsers] = useState([]);
  const [payload, setPayload] = useState();

  const people = useAvailablePeople(defaultValues.peopleId);

  useEffect(() => {
    const getOptions = async () => {
      setUsers(await loadUsers());
      if (action.current === action.options.edit) {
        await loadExternalCalendars(defaultValues.userId);
      }
    };
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onChangePeople = async (e, { value }) => {
    setPayload({ ...payload, peopleId: value });
    await formikApi.setFieldValue("peopleId", value);
    formikApi.setFieldTouched("peopleId", true);
  };

  const onChangeUser = async (e, { value }) => {
    setPayload({ ...payload, userId: value });
    await formikApi.setFieldValue("userId", value);
    formikApi.setFieldTouched("userId", true);
    await loadExternalCalendars(value);
  };

  const loadExternalCalendars = async (userId) => {
    const calendars = await loadExtCalendars(userId);

    /**
     * Assure that while edit, the chosen external
     * option is included among options
     */
    if (defaultValues.external !== undefined) {
      const { id, name, source } = defaultValues.external;
      if (id) calendars.push({ provider: source, id, summary: name });
    }

    try {
      setExtCalendars(calendars);
    } catch (error) {
      setExtCalendars([]);
    }
  };

  const onChangeExtCalendar = async (e, { value }) => {
    if (!value) return;

    const cal = extCalendars.filter((c) => c.id === value)[0];

    setPayload({
      ...payload,
      externalId: cal.id,
      externalName: cal.summary,
      externalSource: cal.provider,
    });

    await formikApi.setFieldValue("externalId", cal.id);
    await formikApi.setFieldValue("externalName", cal.summary);
    await formikApi.setFieldValue("externalSource", cal.provider);

    formikApi.setFieldTouched("externalId", true);
  };

  const renderCalendarsDropdown = () => {
    if (!extCalendars) {
      return <></>;
    }
    if (!extCalendars.length) {
      return <></>;
    }
    let id = null;
    if (defaultValues.external !== undefined) {
      id = defaultValues.external.id;
    }

    return (
      <FormDropdown
        name="externalId"
        label={t("booking.calendar.extCalendar")}
        clearable
        defaultValue={id}
        fluid
        search
        selection
        options={extCalendars.map((e) => ({
          key: e.id,
          text: e.summary,
          value: e.id,
        }))}
        placeholder={t("booking.calendar.extCalendar")}
        onChange={onChangeExtCalendar}
      />
    );
  };
  const renderPeopleDropdown = () => {
    if (!people.length) {
      return <></>;
    }

    return (
      <FormDropdown
        name="peopleId"
        label={t("booking.calendar.people")}
        clearable
        defaultValue={defaultValues.peopleId}
        fluid
        search
        selection
        options={people.map((e) => ({
          key: e._id,
          text: e.fullName,
          value: e._id,
        }))}
        placeholder={t("skill.skill.people")}
        onChange={onChangePeople}
      />
    );
  };

  const renderUserDropdown = () => {
    return (
      <FormDropdown
        name="userId"
        label={t("booking.calendar.user")}
        clearable
        defaultValue={defaultValues.userId}
        fluid
        search
        selection
        options={users.map((e) => ({
          key: e._id,
          text: e.name,
          value: e._id,
        }))}
        placeholder={t("booking.calendar.user")}
        onChange={onChangeUser}
      />
    );
  };

  return (
    <Form className="ui fluid form">
      <FormField name="name" label={t("booking.calendar.name")} />
      {renderPeopleDropdown()}
      {renderUserDropdown()}
      {renderCalendarsDropdown()}
    </Form>
  );
};

export default CustomForm;
