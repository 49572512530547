import { useSelector } from "react-redux";

const useCompany = () => {
  const { session } = useSelector((state) => state);
  const { auth } = session;
  const { googleId, company } = auth;

  if (!googleId) {
    return { ...auth, hasCompany: undefined };
  }

  if (company) {
    return { ...auth, hasCompany: true };
  } else {
    return { ...auth, hasCompany: false };
  }
};

export default useCompany;
