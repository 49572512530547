import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header, Grid, GridRow, GridColumn } from "semantic-ui-react";
import StatusBoard from "../StatusBoard";
import stages from ".";
import NavButtons from "./NavButtons";
import LoadingIndicator from "../LoadingIndicator";
import Context from "../Context";

const MyHeader = () => {
  const { t } = useTranslation();
  const { bookerState } = useContext(Context);
  const { display: stage, statusBoard } = bookerState;
  const { loading } = statusBoard;

  const getTitle = () => {
    switch (stage) {
      case stages.type:
        return t("booking.book.wiz.type.title");
      case stages.level:
        return t("booking.book.wiz.level.title");
      case stages.slot:
        return t("booking.book.wiz.slots.title");
      case stages.confirm:
        return t("booking.book.wiz.confirm.title");
      case stages.end:
        return t("booking.book.wiz.end.title");

      default:
        break;
    }
  };

  const getSub = () => {
    switch (stage) {
      case stages.type:
        return t("booking.book.wiz.type.sub");
      case stages.level:
        return t("booking.book.wiz.level.sub");
      case stages.slot:
        return t("booking.book.wiz.slots.sub");
      case stages.confirm:
        return t("booking.book.wiz.confirm.sub");
      case stages.end:
        return t("booking.book.wiz.end.sub");

      default:
        break;
    }
  };

  const renderStatusBoard = () => {
    if (loading) {
      switch (stage) {
        case stages.slot:
          return <LoadingIndicator text={t("booking.book.loading")} />;
        case stages.end:
          return;

        default:
          return;
      }
    }

    switch (stage) {
      case stages.end:
        return;

      default:
        return <StatusBoard />;
    }
  };

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header as="h2">
            <Header.Content>
              {getTitle()}
              <Header.Subheader>{getSub()}</Header.Subheader>
            </Header.Content>
          </Header>
        </Grid.Column>
        <Grid.Column textAlign={"right"}>
          <NavButtons stage={stage} />
        </Grid.Column>
      </Grid.Row>
      <GridRow>
        <GridColumn>{renderStatusBoard()}</GridColumn>
      </GridRow>
    </Grid>
  );
};

export default MyHeader;
