import React from "react";
import { useLoadBookingRequirements } from "./config/imports";
import { useSelector } from "react-redux";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const startPath = "/booking";

const App = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { appModule } = useSelector((state) => state);
  const { types } = appModule.skill.type;

  useLoadBookingRequirements(types);

  return (
    <>
      <Grid
        textAlign="center"
        style={{ height: "10vh" }}
        verticalAlign="top"
      ></Grid>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment placeholder>
              <Header icon>
                <Icon name="clock outline" />
                {t("home.welcome")}
                <Header.Subheader>{t("home.note")}</Header.Subheader>
              </Header>
              <Button
                secondary
                onClick={() => {
                  navigate(startPath);
                }}
              >
                {t("home.bookingBtn")} <Icon name="right arrow" />
              </Button>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default App;
