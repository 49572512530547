import React, { useContext, useState } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import UserContext from "./UserContext";

const ActionBtns = ({ item, callback }) => {
  const { inviteUser, removeInvite } = useContext(UserContext);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  return (
    <div>
      <Button
        icon="mail forward"
        loading={isLoading}
        disabled={isLoading}
        secondary
        compact
        onClick={async () => {
          setLoading(true);
          await inviteUser(item.email);
          setLoading(false);
        }}
        title={t("user.invite.resend")}
      ></Button>

      <Button
        icon="user cancel"
        disabled={isLoading}
        compact
        onClick={async () => {
          await removeInvite(item._id);
        }}
        title={t("user.invite.cancel")}
        color="red"
      ></Button>
    </div>
  );
};

export default ActionBtns;
