import React, { useContext } from "react";
import BookingContext from "../BookingContext";
import Context from "../Context";
import { Card, Header } from "semantic-ui-react";
import stages from ".";

const Levels = ({ action }) => {
  const itemsPerRow = 3;
  const appContext = useContext(BookingContext);
  const { module } = appContext;
  const context = useContext(Context);
  const { bookerState } = context;
  const { display, type } = bookerState;

  if (display !== stages.level) return <></>;

  if (!type.id) return <></>;

  // se tenho o tipo escolhido, pesquiso todos os skills cadastrados
  // em busca daquele tipo

  const skills = module.skills.filter((skill) => {
    return skill.skillType === type.id;
  });

  const levels = [];
  const levelList = [];

  skills.forEach((skill) => {
    skill.skillLevels.forEach((level) => {
      if (!levelList.includes(level)) {
        levelList.push(level);
        const lvlObj = module.levels.filter((l) => l._id === level);
        if (lvlObj[0] !== undefined) levels.push(lvlObj[0]);
      }
    });
  });

  // agora já tenho os dois técnicos e skills, componho uma lista
  // componho uma lista com as habilidades

  if (levels === undefined) {
    return <div>Can't find services for this type</div>;
  }

  return (
    <Card.Group itemsPerRow={itemsPerRow}>
      {levels.map(({ _id, name, description }) => {
        return (
          <Card key={_id} value={_id} onClick={action}>
            <Card.Content>
              <Card.Header>
                <Header as="h4">{name}</Header>
              </Card.Header>
              <Card.Meta>{description}</Card.Meta>
            </Card.Content>
          </Card>
        );
      })}
    </Card.Group>
  );
};

export default Levels;
