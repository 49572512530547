import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form } from "formik";
import { load as loadPeople } from "../../actions/peopleAction";
import { load as loadLevel } from "../../actions/levelAction";
import { load as loadType } from "../../actions/typeAction";
import { FormField, FormDropdown } from "../../config/imports";

// CustomForm is usually called by CrudForm

const CustomForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikApi = props.api;
  //Default values is needed once Dropdown can't load initialValues
  const defaultValues = props.defaultValues;
  const [people, setPeople] = useState([]);
  const [type, setType] = useState([]);
  const [level, setLevel] = useState([]);
  const [payload, setPayload] = useState({
    owner: "",
    skillType: "",
    skillLevels: [],
  });

  useEffect(() => {
    const getOptions = async () => {
      setPeople(await dispatch(loadPeople()));
      setType(await dispatch(loadType()));
      setLevel(await dispatch(loadLevel()));
    };
    getOptions();
  }, [dispatch]);

  const onChangeOwner = async (e, { value }) => {
    setPayload({ ...payload, owner: value });
    await formikApi.setFieldValue("owner", value);
    formikApi.setFieldTouched("owner", true);
  };

  const onChangeSkillType = async (e, { value }) => {
    setPayload({ ...payload, skillType: value });
    await formikApi.setFieldValue("skillType", value);
    formikApi.setFieldTouched("skillType", true);
  };

  const onChangeSkillLevels = async (e, { value }) => {
    setPayload({ ...payload, skillLevels: value });
    await formikApi.setFieldValue("skillLevels", value);
    formikApi.setFieldTouched("skillLevels", true);
  };

  return (
    <Form className="ui fluid form">
      <FormField name="name" label={t("skill.skill.name")} />
      <FormDropdown
        name="owner"
        label={t("skill.skill.people")}
        clearable
        defaultValue={defaultValues.owner}
        fluid
        search
        selection
        options={people.map((e) => ({
          key: e._id,
          text: e.fullName,
          value: e._id,
        }))}
        placeholder={t("skill.skill.people")}
        onChange={onChangeOwner}
      />
      <FormDropdown
        name="skillType"
        label={t("skill.skill.type")}
        clearable
        defaultValue={defaultValues.skillType}
        fluid
        search
        selection
        options={type.map((e) => ({
          key: e._id,
          text: e.name,
          value: e._id,
        }))}
        placeholder={t("skill.skill.type")}
        onChange={onChangeSkillType}
      />
      <FormDropdown
        name="skillLevels"
        label={t("skill.skill.level")}
        clearable
        defaultValue={defaultValues.skillLevels}
        fluid
        search
        multiple
        selection
        options={level.map((e) => ({
          key: e._id,
          text: e.name,
          value: e._id,
        }))}
        placeholder={t("skill.skill.level")}
        onChange={onChangeSkillLevels}
      />
    </Form>
  );
};

export default CustomForm;
