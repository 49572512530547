import { api, getHeaders } from "../config/api";

export const LOAD_LIST = "@INVITES/LOAD_LIST";

const route = "/company/invite";

export const load = async () => {
  const res = await api.get(route, await getHeaders());
  return res.data;
};

export const invite = async ({ email }) => {
  const data = { email, language: navigator.language };
  const res = await api.post(route, data, await getHeaders());

  return res.data;
};

export const remove = async ({ _id }) => {
  const res = await api.delete(`${route}/${_id}`, await getHeaders());

  return res.data;
};

export const save = (payload) => async (dispatch) => {
  // if (data._id === undefined) {
  //   try {
  //     const res = await api.post(route, data, await getHeaders());
  //     return res;
  //   } catch (e) {
  //     console.log(e);
  //     return null;
  //   }
  // } else {
  // try {
  //   const res = await api.patch(
  //     `${route}/${data._id}`,
  //     data,
  //     await getHeaders()
  //   );
  //   return res;
  // } catch (e) {
  //   console.log(e);
  //   return null;
  // }
  // }
};
