import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthentication } from "../services/auth/AuthService";
import { useSelectLanguage } from "./common";
import Loader from "../components/common/CustomLoader";
import { usePermission } from "./common/hooks";

const PrivateRoute = ({ path }) => {
  // const path = "/home";
  useSelectLanguage();

  const isPermitted = usePermission(path);

  const { isSignedIn, refresh } = useAuthentication();
  const [state, setState] = useState({ badUser: false });

  useEffect(() => {
    refresh(() => {
      setState({ ...state, badUser: true });
    });
  }, [refresh, state]);

  if (state.badUser) {
    return <Navigate to="/login" />;
  }

  if (isPermitted === undefined) {
    return <Loader />;
  }

  if (isPermitted === false) {
    return <Navigate to="/home" />;
  }

  return isSignedIn ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoute;
