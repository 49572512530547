import React from "react";
import { Dropdown } from "semantic-ui-react";
import { ErrorMessage } from "formik";

const FormDropdown = (props) => {
  const {
    clearable,
    defaultValue,
    fluid,
    label,
    multiple,
    name,
    onChange,
    options,
    search,

    selection,
  } = props;

  return (
    <>
      <div className="field">
        <label>
          <h5>
            {label}
            <ErrorMessage
              name={name}
              component="div"
              className="ui left pointing red label"
            />
          </h5>
        </label>
        <Dropdown
          clearable={clearable}
          defaultValue={defaultValue}
          fluid={fluid}
          multiple={multiple}
          onChange={onChange}
          options={options}
          placeholder={label}
          search={search}
          selection={selection}
        />
      </div>
    </>
  );
};

export default FormDropdown;
