import React, { useContext } from "react";
import stages from ".";
import { Grid, GridRow, GridColumn } from "semantic-ui-react";
import ConfirmForm from "../Form";
import Context from "../Context";

const Confirm = () => {
  const { bookerState } = useContext(Context);
  const { display } = bookerState;

  if (display !== stages.confirm) return <></>;

  const renderConfirmation = () => {
    return (
      <Grid>
        <GridRow>
          <GridColumn>
            <ConfirmForm />
          </GridColumn>
        </GridRow>
      </Grid>
    );
  };

  return <>{renderConfirmation()}</>;
};

export default Confirm;
