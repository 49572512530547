import React from "react";
import { Button } from "semantic-ui-react";
import { options } from "./options";

const NewBtn = (props) => {
  if (props.action === options.create) {
    return <></>;
  }

  return (
    <Button circular icon="plus" floated={"right"} onClick={props.onClick} />
  );
};

export default NewBtn;
