import React, { useContext } from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import CompanyContext from "./CompanyContext";
import Edit from "./Edit";

const CustomForm = () => {
  const { t } = useTranslation();
  const { editing, create } = useContext(CompanyContext);

  if (!editing) {
    return <></>;
  }

  const header = create ? t("company.form.create") : t("company.form.change");

  return (
    <Grid textAlign="left" style={{ height: "70vh" }} verticalAlign="middle">
      <Grid.Column>
        <Container>
          <Header>{header}</Header>
          <Edit />
        </Container>
      </Grid.Column>
    </Grid>
  );
};

export default CustomForm;
