import React, { useContext } from "react";
import NewBtn from "./NewBtn";
import ListBtn from "./ListBtn";
import AppContext from "../../../components/AppContext";

const CrudMenu = () => {
  const { action } = useContext(AppContext);
  const { options, current } = action;

  const onClickNewBtn = () => {
    action.set(options.create);
  };

  const onClickListBtn = () => {
    action.set(options.list);
  };

  return (
    <>
      <NewBtn onClick={onClickNewBtn} action={current} />
      <ListBtn onClick={onClickListBtn} action={current} />
    </>
  );
};

export default CrudMenu;
