import React from "react";
import {
  Segment,
  Dimmer,
  Loader,
  Grid,
  GridRow,
  GridColumn,
} from "semantic-ui-react";

const LoadingIndicator = ({ text }) => {
  return (
    <Grid>
      <GridRow>
        <GridColumn>
          <Segment placeholder>
            <Segment.Inline>
              <Dimmer active inverted>
                <Loader size="large">{text}</Loader>
              </Dimmer>
            </Segment.Inline>
          </Segment>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default LoadingIndicator;
