import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/common";
import { useAuthentication } from "../services/auth/AuthService";
import SignUpBtn from "../services/auth/components/SignUpBtn";
import SignInBtn from "../services/auth/components/SignInBtn";
import SignOutBtn from "../services/auth/components/SignOutBtn";

const Login = () => {
  const { t } = useTranslation();
  const { isSignedIn, signIn, signOut } = useAuthentication();

  const [state, setState] = useState({ redirect: false, reload: null });

  const signInAction = () => {
    signIn(() => {
      setState({ redirect: true });
    });
  };

  const signOutAction = () => {
    signOut(() => {
      setState({ reload: true });
    });
  };

  if (state.redirect) {
    return <Navigate to={"/home"} />;
  }

  if (state.reload) {
    window.location.reload();
  }

  const renderLoginBtn = () => {
    if (isSignedIn) {
      return <SignOutBtn action={signOutAction} />;
    }

    return <SignInBtn action={signInAction} />;
  };

  const renderSignUpBtn = () => {
    if (isSignedIn) {
      return;
    }

    return <SignUpBtn />;
  };

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 400 }}>
        <Logo />
        <div className="ui basic segment">{t("domain.slogan")}</div>
        <div className="ui basic segment"></div>
        {renderLoginBtn()} {renderSignUpBtn()}
      </Grid.Column>
    </Grid>
  );
};

export default Login;
