import { LOAD_LIST } from "../actions/typeAction";

const INTIAL_STATE = {
  types: [],
};

const reducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_LIST:
      return { ...state, types: action.payload };
    default:
      return state;
  }
};

export default reducer;
