// eslint-disable-next-line react-hooks/exhaustive-deps

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ToastContainer } from "../../common/toastDecorator";
// import * as Yup from "yup";
import Booker from "./Booker";
import BookingContext from "./BookingContext";
import AppHeader from "./AppHeader";
import { useLoadBookingRequirements } from "../config/imports";

const App = () => {
  const { t } = useTranslation();
  useLoadBookingRequirements();
  const [state, setState] = useState({ module: {} });
  const {
    level,
    people: peoples,
    skill,
    type,
  } = useSelector((state) => state.appModule.skill);

  useEffect(() => {
    const fetch = async () => {
      const skills = skill.skills;
      const types = type.types;
      const levels = level.levels;
      const people = peoples.people;

      setState({ ...state, module: { skills, types, levels, people } });
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, type, peoples, skill]);

  // const validationSchema = Yup.object().shape({
  //   subject: Yup.string()
  //     .min(20, t("validation.length.tooShort"))
  //     .max(500, t("validation.length.tooLong"))
  //     .required(t("validation.missing.field")),
  //   email: Yup.string()
  //     .email(t("validation.wrong.email"))
  //     .required(t("validation.missing.field")),
  // });

  // email: Yup.array.of(t("validation.wrong.email")).required(
  const context = {
    module: state.module,
    color: { on: "blue", off: "grey" },
    form: {
      // validationSchema: validationSchema,
      initialValues: { email: "", subject: "" },
    },
  };

  return (
    <BookingContext.Provider value={context}>
      <AppHeader title={t("booking.book.title")} />
      <Booker />
      <ToastContainer />
    </BookingContext.Provider>
  );
};

export default App;
