import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {
  Login,
  Home,
  Calendars,
  Booking,
  Company,
  Enroll,
  Users,
  Welcome,
} from "../pages";
import TypeApp from "../modules/skill/components/type/App";
import LevelApp from "../modules/skill/components/level/App";
import PeopleApp from "../modules/skill/components/people/App";
import SkillApp from "../modules/skill/components/skill/App";
import InviteApp from "../modules/user/components/InviteApp";
import ManagementApp from "../modules/user/components/ManagementApp";
import "react-toastify/dist/ReactToastify.min.css";
import "../config/i18n";

const App = () => {
  return (
    <div className="ui container">
      <Suspense fallback={null}>
        <Router>
          <Routes>
            <Route path="/*" element={<Welcome />} />
            <Route path="/login/*" element={<Login />} />
            <Route path="/enroll/:token" element={<Enroll />} />
            <Route path="/home" element={<PrivateRoute path="/home" />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route path="/booking" element={<PrivateRoute path="/booking" />}>
              <Route path="/booking" element={<Booking />} />
            </Route>

            <Route path="/skill" element={<PrivateRoute path="/skill" />}>
              <Route path="/skill" element={<SkillApp />} />
              <Route path="/skill/level" element={<LevelApp />} />
              <Route path="/skill/type" element={<TypeApp />} />
              <Route path="/skill/people" element={<PeopleApp />} />
            </Route>
            <Route
              path="/calendars"
              element={<PrivateRoute path="/calendars" />}
            >
              <Route path="/calendars" element={<Calendars />} />
            </Route>
            <Route path="/company" element={<PrivateRoute path="/company" />}>
              <Route path="/company" element={<Company />} />
            </Route>
            <Route path="/users" element={<PrivateRoute path="/users" />}>
              <Route path="/users" element={<Users />} />
              <Route path="/users/management" element={<ManagementApp />} />
              <Route path="/users/invites" element={<InviteApp />} />
            </Route>
            {/* <Route path="" element={<PrivateRoute path=""/>}>
              <Route path="" element={} />
            </Route> */}
          </Routes>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
