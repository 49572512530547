import React from "react";
import { Form } from "formik";
import { FormField } from "../../config/imports";
import { useTranslation } from "react-i18next";

const CustomForm = () => {
  const { t } = useTranslation();

  return (
    <Form className="ui fluid form">
      <FormField name="fullName" label={t("skill.people.fullName")} />
    </Form>
  );
};

export default CustomForm;
