import React from "react";
import { Header } from "../components/page";
import App from "../modules/calendar/components/App";

const Calendars = () => {
  return (
    <>
      <Header />
      <App />
    </>
  );
};

export default Calendars;
