import React, { useEffect, useState } from "react";
import { AppContext, PageHeader, options } from "../config/imports";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMyToast, ToastContainer, toast } from "../../common/toastDecorator";
import CrudSwitch from "../../common/crud/CrudSwitch";
import ItemTemplate from "./templates/ListItem";
import Form from "./Form";
import * as Yup from "yup";

import { save, load } from "../actions/calendarAction";

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myToast = useMyToast();
  const [items, setItems] = useState(null);
  const [state, setState] = useState({
    action: options.list,
    id: null,
  });

  useEffect(() => {
    const fetch = async () => {
      const items = await dispatch(load());
      setItems(items);

      await dispatch(load());
    };
    fetch();
  }, [dispatch]);

  const reloadList = async () => {
    setItems(await dispatch(load()));
  };

  const setAction = (action, id) => {
    setState({ ...state, action, id });
  };

  const onSubmit = async (payload) => {
    await setState({ action: options.list });
    await dispatch(save(payload)).then((isSuccess) => {
      isSuccess
        ? toast.success(myToast.success, { ...myToast.params })
        : toast.error(myToast.error);
    });
    setItems(await dispatch(load()));
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
    peopleId: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
    userId: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
  });

  const providerData = {
    action: {
      current: state.action,
      targetId: state.id,
      options: options,
      set: setAction,
      onSubmit: onSubmit,
      reloadList,
    },
    items,
    title: t("booking.calendar.title"),
    templates: {
      card: ItemTemplate,
    },
    form: {
      fields: Form,
      validationSchema: validationSchema,
      initialValues: {
        name: "",
        userId: "",
        peopleId: "",
        external: { id: "", source: "", name: "" },
      },
    },
  };

  return (
    <AppContext.Provider value={providerData}>
      <PageHeader />
      <CrudSwitch />
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
