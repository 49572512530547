import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Icon } from "semantic-ui-react";
import { Logo } from "../components/common";
import { useTranslation } from "react-i18next";

const Welcome = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 400 }}>
        <Logo />
        <Button
          icon
          labelPosition="right"
          onClick={() => {
            navigate("/home");
          }}
        >
          {t("home.clickToBegin")}
          <Icon name="right arrow" />
        </Button>
      </Grid.Column>
    </Grid>
  );
};

export default Welcome;
