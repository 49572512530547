import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { saveTokenInLocalStorage, handleGoogleAuth2 } from "../AuthService";
import { registerUser } from "../actions";
import requester from "../../../middleware/AuthAPI";
import { Navigate } from "react-router-dom";

const SignUpBtn = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState({ hidden: false, redirect: false });

  const handleSignUp = (e, d) => {
    const sendCode = async (googleCode) => {
      try {
        const req = await requester.post("/signup", googleCode);
        const { user, token } = req.data;
        saveTokenInLocalStorage(token);
        dispatch(registerUser(user));
        setState({ ...state, redirect: true });
      } catch (error) {
        setState({ hidden: true });
        console.log(`Not able not post the Google's auth code: ${error}`);
      }
    };

    handleGoogleAuth2(sendCode);
  };

  if (state.hidden) {
    return <></>;
  }

  if (state.redirect) {
    return <Navigate to={"/home"} />;
  }

  return (
    <button onClick={handleSignUp} className="ui blue google button">
      <i className="google icon" />
      {t("auth.signUp")}
    </button>
  );
};

export default SignUpBtn;
