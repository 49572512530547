import React, { useState } from "react";
import { Menu, Container, Dropdown, Icon } from "semantic-ui-react";
import { Logo } from "../../common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRole } from "../../common/hooks";
import { useAuthentication } from "../../../services/auth/AuthService";

const MenuBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { role, name } = useRole();
  const { signOut } = useAuthentication();
  const [adm, sup] = ["admin", "supervisor", "user"];

  const [state, setState] = useState({
    redirect: null,
    signOut: false,
  });

  const handleItemClick = async (e, { value }) => {
    navigate(value);
  };

  const signOutAction = () => {
    signOut(() => {
      setState({ signOut: true });
    });
  };

  if (state.signOut) {
    navigate("/login?state=signedOut");
  }

  const renderSkillMgmt = () => {
    if (role !== adm && role !== sup) {
      return;
    }

    return (
      <>
        <Dropdown text={t("header.menu.skill")} pointing className="link item">
          <Dropdown.Menu>
            <Dropdown.Header>
              <Icon name="cogs" />
              {t("header.menu.settings")}
            </Dropdown.Header>
            <Dropdown.Item onClick={handleItemClick} value={"/skill/level"}>
              {t("header.menu.levels")}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleItemClick} value={"/skill/type"}>
              {t("header.menu.types")}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleItemClick} value={"/skill/people"}>
              {t("header.menu.people")}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>
              <Icon name="star" />
              {t("header.menu.skill")}
            </Dropdown.Header>
            <Dropdown.Item onClick={handleItemClick} value={"/skill"}>
              {t("header.menu.management")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          name="calendars"
          value={"/calendars"}
          onClick={handleItemClick}
        >
          {t("header.menu.calendars")}
        </Menu.Item>
      </>
    );
  };

  const renderBookings = () => {
    return (
      <Menu.Item name="booking" value={"/booking"} onClick={handleItemClick}>
        {t("header.menu.booking")}
      </Menu.Item>
    );
  };

  const renderCompanyMgmt = () => {
    if (role !== adm) {
      return;
    }

    return (
      <Dropdown.Item name="company" value="/company" onClick={handleItemClick}>
        {t("header.menu.company")}
      </Dropdown.Item>
    );
  };

  return (
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item onClick={handleItemClick} value={"/home"} as="a" header>
          <Logo size={"tiny"} />
        </Menu.Item>

        {renderSkillMgmt()}

        {renderBookings()}

        <Menu.Menu position="right">
          {/* <Menu.Item></Menu.Item> */}
          <Dropdown item text={name}>
            <Dropdown.Menu>
              {renderCompanyMgmt()}
              <Dropdown.Item onClick={signOutAction}>
                <Icon name="left arrow" color="red" />
                {t("header.menu.signout")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default MenuBar;
