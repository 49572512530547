import api from "../../../middleware/AuthAPI";
import { getTokenInFromLocalStorage } from "../config/imports";

const getHeaders = async () => {
  const token = await getTokenInFromLocalStorage();

  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};

export { api, getHeaders };
