import { api, getHeaders } from "../config/api";

export const LOAD_COMPANY = "@CALENDAR/LOAD_COMPANY";
//export const LOAD_EXTERNAL = "@CALENDAR/LOAD_EXTERNAL";

const route = "/company";

export const load = () => async (dispatch) => {
  const res = await api.get(route, await getHeaders());
  await dispatch({
    type: LOAD_COMPANY,
    payload: res.data,
  });

  return res.data;
};

export const enroll = async (token) => {
  const data = { token };

  const res = await api.post(
    `${route}/invite/enroll`,
    data,
    await getHeaders()
  );

  return res.data;
};

export const loadInvite = async (token) => {
  const res = await api.get(`${route}/invite/${token}`, await getHeaders());

  return res.data;
};

export const save = (payload) => async (dispatch) => {
  const { _id, name } = payload;

  const data = { _id, name };

  if (data._id === undefined) {
    try {
      const res = await api.post(route, data, await getHeaders());
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  } else {
    try {
      const res = await api.patch(`${route}`, data, await getHeaders());
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
};
