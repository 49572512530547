import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  loadAvailablePeople,
  loadPeople,
  AppContext,
} from "../../calendar/config/imports";

/**
 * Retorna as opções de People disponíveis para seleção
 *
 * @param {*} peopleId
 * @returns
 */
const useAvailablePeople = (peopleId) => {
  const { action } = useContext(AppContext);
  const dispatch = useDispatch();
  const [state, setState] = useState({});

  useEffect(() => {
    async function foo() {
      const options = await handlePeopleOptions();

      setState(options);
    }
    foo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePeopleOptions = async () => {
    const allPeople = await dispatch(loadPeople());
    const availablePeople = await loadAvailablePeople();

    if (action.current === action.options.edit) {
      const edit = allPeople.filter(
        (people) => String(people._id) === String(peopleId)
      )[0];

      availablePeople.push(edit);
    }

    return availablePeople;
  };

  return state;
};

export { useAvailablePeople };
