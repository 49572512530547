import React, { useEffect, useState } from "react";
import { AppContext, PageHeader, options, Header } from "../../config/imports";
import { save, load } from "../../actions/peopleAction";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMyToast, ToastContainer, toast } from "../../config/toastDecorator";
import CRUDPage from "../../../common/crud/CrudSwitch";
import ItemTemplate from "./templates/ListItem";
import Form from "./PeopleForm";
import * as Yup from "yup";

const App = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myToast = useMyToast();
  const [items, setItems] = useState(null);
  const [state, setState] = useState({
    action: options.list,
    id: null,
  });

  const people = useSelector((state) => state.appModule.skill.people.people);

  useEffect(() => {
    if (people[0]) {
      setItems(people);
      return;
    }
    const fetch = async () => setItems(await dispatch(load()));
    fetch();
  }, [dispatch, people]);

  const reloadPeopleList = async () => {
    setItems(await dispatch(load()));
  };

  const setAction = (action, id) => {
    setState({ ...state, action, id });
  };

  const onSubmit = async (payload) => {
    setState({ action: options.list });
    dispatch(save(payload)).then(async (isSuccess) => {
      reloadPeopleList();
      isSuccess
        ? toast.success(myToast.success, { ...myToast.params })
        : toast.error(myToast.error);
    });
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, t("validation.length.tooShort"))
      .max(50, t("validation.length.tooLong"))
      .required(t("validation.missing.field")),
  });

  const providerData = {
    action: {
      current: state.action,
      targetId: state.id,
      options: options,
      set: setAction,
      onSubmit: onSubmit,
      reloadPeopleList,
    },
    items,
    title: t("skill.people.title"),
    templates: {
      card: ItemTemplate,
    },
    form: {
      fields: Form,
      validationSchema: validationSchema,
      initialValues: {
        fullName: "",
      },
    },
  };

  return (
    <AppContext.Provider value={providerData}>
      <Header />
      <PageHeader />
      <CRUDPage />
      <ToastContainer />
    </AppContext.Provider>
  );
};

export default App;
