import React from "react";
import logo from "./agendador.png";
import { Image } from "semantic-ui-react";

const Logo = ({ size }) => {
  const logoSize = size ? size : "small";

  return (
    <Image
      src={logo}
      spaced={"left"}
      size={logoSize}
      style={{ marginRight: "1.5em" }}
      verticalAlign={"bottom"}
    />
  );
};

export default Logo;
