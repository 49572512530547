import React, { useContext, useState } from "react";
import CompanyContext from "./CompanyContext";
import { Header, Icon, Segment, Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  const { company, actionOnEdit, displayHome } = useContext(CompanyContext);
  const [state, setState] = useState({ goToHome: false });

  if (!displayHome) {
    return <></>;
  }

  const goToHome = () => {
    setState({ ...state, goToHome: true });
  };

  const goToUsers = () => {
    setState({ ...state, goToUsers: true });
  };

  if (state.goToHome) {
    return <Navigate to="/home" />;
  }

  if (state.goToUsers) {
    return <Navigate to="/users" />;
  }

  return (
    <Grid textAlign="left" style={{ height: "70vh" }} verticalAlign="middle">
      <Grid.Column>
        <Segment placeholder>
          <Header icon>
            <Icon name="building outline" />
            {t("company.home.profile")}
            <Header.Subheader>
              {t("company.home.name")}: {company.name}
            </Header.Subheader>
          </Header>
          <Segment.Inline>
            <Button onClick={actionOnEdit} secondary>
              {t("btn.edit")}
            </Button>
            <Button onClick={goToHome} secondary>
              {t("btn.start")}
            </Button>
            <Button onClick={goToUsers} secondary>
              {t("btn.users")}
            </Button>
          </Segment.Inline>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Home;
