import { useTranslation } from "react-i18next";
export * from "react-toastify";

export function useMyToast(props) {
  const { t } = useTranslation();

  return {
    success: t("form.submit.success"),
    error: t("form.submit.error"),
    duplicated: t("form.submit.duplicated"),
    params: {
      theme: "light",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  };
}
