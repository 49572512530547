import React, { useContext } from "react";
import { Form } from "formik";
import { FormField, AppContext, FormDropdown } from "../../config/imports";
import { useTranslation } from "react-i18next";

const LevelForm = ({ api: formikApi, defaultValues }) => {
  const { duration } = useContext(AppContext);
  const { t } = useTranslation();

  const onChangeDuration = async (e, { value }) => {
    await formikApi.setFieldValue("durationInMinutes", value);
    formikApi.setFieldTouched("durationInMinutes", true);
  };

  return (
    <Form className="ui fluid form">
      <FormField name="name" label={t("skill.level.name")} />
      <FormDropdown
        name="durationInMinutes"
        label={t("skill.level.duration")}
        clearable
        defaultValue={defaultValues.durationInMinutes}
        selection
        options={duration.map((e) => ({
          key: e.key,
          text: e.value,
          value: e.value,
        }))}
        placeholder={t("skill.level.durationPlaceholder")}
        onChange={onChangeDuration}
      />
      <FormField name="description" label={t("skill.level.description")} />
      <FormField name="tags" label={t("skill.level.tags")} />
    </Form>
  );
};

export default LevelForm;
