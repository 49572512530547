import { combineReducers } from "redux";
import levelReducer from "./levelReducer";
import peopleReducer from "./peopleReducer";
import skillReducer from "./skillReducer";
import typeReducer from "./typeReducer";

//Skill
export default combineReducers({
  level: levelReducer,
  people: peopleReducer,
  skill: skillReducer,
  type: typeReducer,
});
