import React from "react";
import { Form } from "formik";
import { FormField } from "../../config/imports";
import { useTranslation } from "react-i18next";

const TypeForm = () => {
  const { t } = useTranslation();

  return (
    <Form className="ui fluid form">
      <FormField name="name" label={t("skill.type.name")} />
      <FormField name="shortName" label={t("skill.type.shortName")} />
      <FormField name="tags" label={t("skill.type.tags")} />
    </Form>
  );
};

export default TypeForm;
