import React, { useContext } from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Form,
  Input,
  Grid,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import UserContext from "./UserContext";

function InviteModal({ open, callback }) {
  const { onSubmit } = useContext(UserContext);
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("validation.wrong.email"))
      .required(t("validation.required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Modal open={open}>
      <Modal.Header>{t("user.invite.inviteUser")}</Modal.Header>
      <Modal.Content image>
        <Image
          size="medium"
          src="https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/1688838/invitation-clipart-md.png"
          wrapped
        />
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header>{t("user.invite.inviteNew")}</Header>
                <p>{t("user.invite.inviteTxt")}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Field></Form.Field>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div>
                  <label>
                    Email:
                    {formik.errors.email ? (
                      <div className="ui left pointing red label">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </label>
                </div>
                <Input
                  id="email"
                  name="email"
                  style={{ width: "300px" }}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={callback}>
          {t("user.invite.cancelBtn")}
        </Button>
        <Button
          type="submit"
          content={t("user.invite.confirmBtn")}
          labelPosition="right"
          icon="mail"
          positive
          onClick={formik.handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default InviteModal;
