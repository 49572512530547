import React, { useContext } from "react";
import CompanyContext from "./CompanyContext";
import { Formik, Form } from "formik";
import { SaveButton, FormField } from "../config/imports";
import { useTranslation } from "react-i18next";

const CustomForm = () => {
  const { t } = useTranslation();
  const context = useContext(CompanyContext);
  const { onSubmit, validationSchema, initialValues, editing } = context;

  if (!editing) {
    return <></>;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ touched, errors, submitForm }) => (
          <Form className="ui fluid form">
            <FormField name="name" label={t("company.form.name")} />
            <SaveButton
              touched={touched}
              errors={errors}
              submitForm={submitForm}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomForm;
