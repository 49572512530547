import _ from "lodash";
import { useSelector } from "react-redux";
import roles from "../rolesMap";

/**
 * useRoles
 *
 * - Used by Menu, returns role and routes alowed
 *
 * @returns [email, role, routes]
 */
export const useRole = () => {
  const {
    session: {
      auth: { role: userRole, email, name },
    },
  } = useSelector((state) => state, _.isEqual);

  return { name, email, role: userRole, routes: roles[userRole] };
};

/**
 * usePermission
 *
 * - Verify if user has permission on module
 * - Used by PrivateRoute
 *
 * @param {*} path route received from read router
 * @returns
 */
const usePermission = (path) => {
  const {
    session: {
      auth: { role: userRole },
    },
  } = useSelector((state) => state, _.isEqual);

  if (!userRole) {
    return undefined;
  }

  const module = path.split("/")[1];

  if (roles[userRole].includes(module)) {
    return true;
  }

  console.log(`bad permission: ${userRole}:${module}`);
  return false;
};

export default usePermission;
